import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export const useHover = () => {
  const [isHovered, setHovered] = useState(false);

  const handleHover = (hoverState: boolean, disabled = false) => () => {
    if (!disabled) {
      setHovered(hoverState);
    }
  };

  const setHover = (hoverState: boolean) => {
    setHovered(hoverState);
  };

  return {
    isHovered,
    handleHover,
    setHover,
  };
};

export const useVisibilityAnimation: (
  disappearOffset: number,
  defaultVisible?: boolean
) => [any, Dispatch<SetStateAction<boolean>>] = (
  disappearOffset,
  defaultVisible
) => {
  const [visible, setVisible] = useState(defaultVisible);
  const [noDisplay, setNoDisplay] = useState(!defaultVisible);

  useEffect(() => {
    if (!visible && !noDisplay) {
      const disappearEffect = setTimeout(() => {
        setNoDisplay(true);
      }, disappearOffset);
      return () => clearTimeout(disappearEffect);
    }

    if (visible) setNoDisplay(false);
  }, [visible]);

  const style = noDisplay ? { display: 'none' } : null;

  return [style, setVisible];
};

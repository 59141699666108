import React, { FunctionComponent, useState, useEffect } from 'react';
import Styled, { css } from 'styled-components';
import { Styles } from '../util/Styles';
import { Icon } from './Icon';

interface IChipProps {
  label: string;
  value?: string;
  color?:
    | 'blue'
    | 'indigo'
    | 'red'
    | 'green'
    | 'yellow'
    | 'orange'
    | 'byzantium'
    | 'purple'
    | '';
  onClose?: (val: string) => void;
  maxWidth?: string;
  dataTestId?: string;
}

interface IWrapperProps {
  backgroundColor: string;
  borderColor: string;
  maxWidth?: string;
}

const Wrapper = Styled.div<IWrapperProps>`
  padding: 2px 4px;
  display: inline-block;
  border-radius: ${Styles.borderRadius.s};
  
  ${({ borderColor }) =>
    borderColor &&
    css`
      border: 1px solid ${borderColor};
    `}
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  user-select: none;
`;

const ContentWrapper = Styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
`;

interface ILabelWrapperProps {
  fontColor: string;
}

const LabelWrapper = Styled.div<ILabelWrapperProps>`
  display: inline-block;
  font-family: ${Styles.fonts.primary};
  font-weight: ${Styles.fontWeights.medium};
  ${({ fontColor }) =>
    fontColor &&
    css`
      color: ${fontColor};
    `}
  font-size: ${Styles.fontSizes.s1};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IconWrapper = Styled.div`
  color: ${Styles.colors.text.darkPlaceholder};
  cursor: pointer;

  margin-left: 6px;
  font-size: ${Styles.fontSizes.s1};
  margin-top: 1px;

  span:hover {
    background: transparent;
  }
`;

export const Chip: FunctionComponent<IChipProps> = ({
  label = '',
  value = label,
  color = Styles.colors.white,
  onClose,
  maxWidth,
  dataTestId = 'chip',
}) => {
  const [backgroundColor, setBackgroundColor] = useState('');
  const [fontColor, setFontColor] = useState('');
  const [borderColor, setBorderColor] = useState('');
  const [closeIconColor, setCloseIconColor] = useState('');

  useEffect(() => {
    let newBackgroundColor: string = '';
    let newFontColor: string = '';
    let newBorderColor: string = '';
    let newCloseIconColor: string = '';

    switch (color) {
      case 'blue':
        newBackgroundColor = Styles.colors.blue100;
        newFontColor = Styles.colors.blue700;
        newBorderColor = `${Styles.colors.blue500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.blue500;
        break;
      case 'indigo':
        newBackgroundColor = Styles.colors.indigo100;
        newFontColor = Styles.colors.indigo700;
        newBorderColor = `${Styles.colors.indigo500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.indigo500;
        break;
      case 'red':
        newBackgroundColor = Styles.colors.red100;
        newFontColor = Styles.colors.red700;
        newBorderColor = `${Styles.colors.red500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.red500;
        break;
      case 'green':
        newBackgroundColor = Styles.colors.green100;
        newFontColor = Styles.colors.green700;
        newBorderColor = `${Styles.colors.green500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.green500;
        break;
      case 'yellow':
        newBackgroundColor = Styles.colors.yellow100;
        newFontColor = Styles.colors.yellow700;
        newBorderColor = `${Styles.colors.yellow500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.yellow500;
        break;
      case 'orange':
        newBackgroundColor = Styles.colors.orange100;
        newFontColor = Styles.colors.orange700;
        newBorderColor = `${Styles.colors.orange500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.orange500;
        break;
      case 'byzantium':
        newBackgroundColor = Styles.colors.byzantium100;
        newFontColor = Styles.colors.byzantium700;
        newBorderColor = `${Styles.colors.byzantium500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.byzantium500;
        break;
      case 'purple':
        newBackgroundColor = Styles.colors.purple100;
        newFontColor = Styles.colors.purple700;
        newBorderColor = `${Styles.colors.purple500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.purple500;
        break;
      default:
        newBackgroundColor = Styles.colors.neutral100;
        newFontColor = Styles.colors.neutral700;
        newBorderColor = `${Styles.colors.neutral500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.neutral500;
    }

    setBackgroundColor(newBackgroundColor);
    setFontColor(newFontColor);
    setBorderColor(newBorderColor);
    setCloseIconColor(newCloseIconColor);
  }, [color]);

  const renderCloseIcon = () => {
    if (onClose) {
      return (
        <IconWrapper iconPosition="right" data-testid="closeChipIcon">
          <Icon
            name="faTimes"
            ariaLabel="close chip"
            color={closeIconColor}
            onClick={handleCloseIconClick}
          />
        </IconWrapper>
      );
    }
  };

  const handleCloseIconClick = () => {
    onClose(value);
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      borderColor={borderColor}
      data-testid={dataTestId}
      maxWidth={maxWidth}
    >
      <ContentWrapper>
        <LabelWrapper fontColor={fontColor}>{label}</LabelWrapper>
        {renderCloseIcon()}
      </ContentWrapper>
    </Wrapper>
  );
};

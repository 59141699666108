import { Colors } from './Colors';

export const Styles = {
  transitions: {
    button: 'all 0.18s cubic-bezier(0.645, 0.045, 0.355, 1)',
    switch: 'all 0.18s cubic-bezier(0.78, 0.14, 0.15, 0.86)',
    pagination: 'all 0.31s cubic-bezier(0.645, 0.045, 0.355, 1)',
    textfield: 'border-color 0.1s cubic-bezier(0.33, 0.49, 0.21, 1.17)',
  },
  borderRadius: {
    xs: '2px',
    s: '4px',
    m: '6px',
  },
  fonts: {
    primary: 'Inter, Arial',
  },
  fontLineHeights: {
    base: '22.75px',
    large: '40px', // TODO: find the correct name for this
  },
  fontSizes: {
    base: '16px',
    code: '14.22px',
    s1: '11.24px',
    s2: '12.64px',
    s3: '14.22px',
    m1: '16px',
    m2: '18px',
    m3: '20.25px',
    m4: '22.75px',
    m5: '25.63px',
    l1: '28.83px',
    l2: '32.44px',
    l3: '36.49px',
    l4: '41.05px',
    l5: '46.18px',
    xl1: '51.96px',
    xl2: '58.45px',
    xl3: '65.76px',
    xl4: '73.98px',
    xl5: '83.23px',
  },
  fontWeights: {
    thin: 100,
    extralight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  colors: { ...Colors },
  gradients: {
    unity: `linear-gradient(90deg, ${Colors.integrateBlue} 0%, ${Colors.integrateGreen} 52.17%, ${Colors.integrateOrange} 100%)`,
  },
};

import React, { FunctionComponent, useState, useEffect } from 'react';
import { Styles } from '../util/Styles';
import Styled, { css } from 'styled-components';
import { Icon } from './Icon';
import { PaginationCell } from './PaginationCell';

const PaginationContainer = Styled.nav`
  display: block;
  width: max-content;
  padding: 4px;
`;

const PaginationBody = Styled.span`
  background-color: ${Styles.colors.neutral100};
  border-radius: ${Styles.borderRadius.s};
  user-select: none;
  margin: 0 8px;
  height: 32px;
  transition: all 0.40s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-block;
`;

interface IIconWrapperProps {
  disabled: boolean;
}

const IconWrapper = Styled.button<IIconWrapperProps>`
  display: inline-block;
  vertical-align: top;
  height: 32px;
  background: none;
  border: 0;
  align-items: center;
  cursor: pointer;
  padding: 0 8px;
  transform-origin: center;
  border-radius: ${Styles.borderRadius.s};
  transition: opacity 0.1s ease-in;

  &:hover {
    background-color: ${Styles.colors.neutral300};
  }

  &:active {
    background-color: ${Styles.colors.blue500};
    color: ${Styles.colors.white};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;

      &:hover {
        transform: scale(1);
      }
    `}
`;

export interface IPaginationProps {
  amountOfPages: number;
  initialPage?: number;
  dataTestId?: string;
  onChange: (page: number) => void;
}

export const Pagination: FunctionComponent<IPaginationProps> = ({
  dataTestId = 'pagination',
  amountOfPages,
  initialPage = 1,
  onChange,
}) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const isOnFirstPage = currentPage === 1;
  const isOnLastPage = currentPage === amountOfPages;
  const amountOfVisibleCells = 7;
  const centerOfVisibleCells = 4;

  useEffect(() => {
    initialPage > amountOfPages ? gotoPage(1) : gotoPage(initialPage);
  }, [initialPage, amountOfPages]);

  const gotoPage = (page: number) => {
    const isValid = page > 0 && page <= amountOfPages;
    isValid && setCurrentPage(page);
  };

  const gotoPageAndTriggerChange = (newPage: number) => {
    gotoPage(newPage);
    onChange(newPage);
  };

  const handleClickCell = (page: number) => () => {
    const onPageAlready = currentPage === page;
    !onPageAlready && gotoPageAndTriggerChange(page);
  };

  const handleNextPage = () => {
    const onLastPage = currentPage === amountOfPages;
    !onLastPage && gotoPageAndTriggerChange(currentPage + 1);
  };

  const handlePrevPage = () => {
    const onFirstPage = currentPage === 1;
    !onFirstPage && gotoPageAndTriggerChange(currentPage - 1);
  };

  const renderedPaginationCell = (value: number) => {
    return (
      <PaginationCell
        dataTestId={`${dataTestId}-cell-${value}`}
        key={`cell-${value}`}
        isSelected={value === currentPage}
        value={value.toString()}
        onClick={handleClickCell(value)}
      />
    );
  };

  const divider = (name: string) => {
    return (
      <PaginationCell
        key={`divider-${name}`}
        dataTestId={`${dataTestId}-divider-${name}`}
        value={'...'}
        disabled
      ></PaginationCell>
    );
  };

  const calcOffset = (page: number) => {
    let offset = page - centerOfVisibleCells;
    const isSmall = offset < 0;
    const isLarge = offset >= amountOfPages - amountOfVisibleCells;
    const maxOffset = amountOfPages - amountOfVisibleCells;

    if (isSmall) return 0;

    return isLarge ? maxOffset : offset;
  };

  //1 ... 5 6
  const renderedLeftPosition = () => {
    let visibleCells = [];
    visibleCells.push(renderedPaginationCell(1));
    visibleCells.push(divider('right'));
    const offset = calcOffset(currentPage);
    for (let i = offset + 1; i <= amountOfPages; i++) {
      visibleCells.push(renderedPaginationCell(i));
    }
    return visibleCells;
  };

  //1 2 ... 6
  const renderedRightPosition = () => {
    let visibleCells = [];
    for (let i = 1; i <= amountOfVisibleCells; i++) {
      visibleCells.push(renderedPaginationCell(i));
    }
    visibleCells.push(divider('left'));
    visibleCells.push(renderedPaginationCell(amountOfPages));
    return visibleCells;
  };

  //1 ... 3 ... 6
  const renderedCenterPosition = () => {
    let visibleCells = [renderedPaginationCell(1)];
    visibleCells.push(divider('left'));
    const offset = calcOffset(currentPage);

    for (let i = 2; i < amountOfVisibleCells; i++) {
      visibleCells.push(renderedPaginationCell(i + offset));
    }
    visibleCells.push(divider('right'));
    visibleCells.push(renderedPaginationCell(amountOfPages));
    return visibleCells;
  };

  // 1 2 3 4 5 6
  const renderedNoDividers = () => {
    let visibleCells = [];
    for (let i = 1; i <= amountOfPages; i++) {
      visibleCells.push(renderedPaginationCell(i));
    }
    return visibleCells;
  };

  const renderedCells = () => {
    const isOnFirstPages = currentPage <= centerOfVisibleCells;
    const isOnLastPages =
      currentPage >= amountOfPages - (centerOfVisibleCells - 1);
    const hasFewPages = amountOfPages <= amountOfVisibleCells;

    let position = 'center';
    if (isOnFirstPages) position = 'right';
    if (isOnLastPages) position = 'left';
    if (hasFewPages) position = 'none';

    switch (position) {
      case 'left':
        return renderedLeftPosition();
      case 'right':
        return renderedRightPosition();
      case 'center':
        return renderedCenterPosition();
      default:
        return renderedNoDividers();
    }
  };

  return (
    <PaginationContainer role="navigation" aria-label="Pagination navigation">
      <IconWrapper
        onClick={handlePrevPage}
        data-testid={`${dataTestId}-left-button`}
        disabled={isOnFirstPage}
        aria-disabled={isOnFirstPage}
        rel="prev"
      >
        <Icon ariaLabel="Previous Page" name="faChevronLeft" opacity={0.5} />
      </IconWrapper>
      <PaginationBody data-testid={dataTestId}>
        {renderedCells()}
      </PaginationBody>
      <IconWrapper
        onClick={handleNextPage}
        data-testid={`${dataTestId}-right-button`}
        disabled={isOnLastPage}
        aria-disabled={isOnLastPage}
        rel="next"
      >
        <Icon ariaLabel="Next Page" name="faChevronRight" opacity={0.5} />
      </IconWrapper>
    </PaginationContainer>
  );
};

import React, { FunctionComponent, useState } from 'react';
import { Styles } from '../util/Styles';
import Styled, { css } from 'styled-components';

export interface ISwitchProps {
  isDefaultChecked?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  dataTestId?: string;
  small?: boolean;
}

interface IWrapperProps {
  isChecked: boolean;
  dataTestId: string;
  small: boolean;
  disabled: boolean;
}

const Wrapper = Styled.button<IWrapperProps>`
outline: 0;
margin: 0;
padding: 0;
position: relative;
display: inline-block;
box-sizing: border-box;
min-width: 44px;
height: 22px;
vertical-align: middle;
border: 1px solid transparent;
border-radius: 100px;
cursor: pointer;
&:after {
  position: absolute;
  top: 1px;
  width: 18px;
  height: 18px;
  background-color: ${Styles.colors.white};
  border-radius: 18px;
  cursor: pointer;
  transition: ${Styles.transitions.switch};
  content: ' ';
}

${({ isChecked }) =>
  isChecked
    ? `
  background-color: ${Styles.colors.blue500};
  transition: background-color 0.28s ease-in;
  &:after {
    left: 100%;
    margin-left: -1px;
    transform: translateX(-100%);
  }
`
    : `
  background-color: ${Styles.colors.neutral500};
  transition: background-color 0.3s ease-out;
  &:after {
    top: 1px;
    left: 1px;
  }
`}

${({ isSmall }) =>
  isSmall &&
  css`
    min-width: 28px;
    height: 16px;
    line-height: 14px;
    &:after {
      width: 12px;
      height: 12px;
    }
  `}

${({ disabled }) =>
  disabled &&
  css`
    cursor: not-allowed;
    opacity: 0.4;
    &:after {
      cursor: not-allowed;
    }
  `}
`;

export const Switch: FunctionComponent<ISwitchProps> = ({
  isDefaultChecked = false,
  onChange,
  dataTestId = 'switch',
  small = false,
  disabled = false,
}) => {
  const [isChecked, setIsChecked] = useState(isDefaultChecked);

  const handleClick = () => {
    onChange(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <Wrapper
      isSmall={small}
      isChecked={isChecked}
      onClick={handleClick}
      data-testid={dataTestId}
      disabled={disabled}
    ></Wrapper>
  );
};

import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  ReactElement,
} from 'react';
import Styled, { css } from 'styled-components';
import { Styles } from '../util/Styles';

interface ITooltipContainerProps {
  arrowDirection?: 'top' | 'right' | 'bottom' | 'left' | '';
  arrowPlacement?: number;
  width?: string;
  maxWidth?: string;
  visible?: boolean;
}

const TooltipContainer = Styled.div<ITooltipContainerProps>`
  z-index: 1;
  pointer-events: none;
  padding: 12px;
  background-color: ${Styles.colors.neutral900};  
  position: absolute; 
  box-shadow: 0 2px 3px -1px ${Styles.colors.black}${
  Styles.colors.opacity10
}, 0 2px 2px 0 ${Styles.colors.black}${Styles.colors.opacity10}, 0 1px 4px 0 ${
  Styles.colors.black
}${Styles.colors.opacity20};
  box-sizing: border-box;
  width: fit-content;
  border-radius: ${Styles.borderRadius.s};
  &:after {
    content:'';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
  }
  opacity: 0;
  transition: opacity 0.08s ease-out;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
    `}
  
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ visible }) =>
    visible &&
    css`
      pointer-events: default;
    `}

  ${({ arrowDirection, arrowPlacement }) =>
    arrowDirection === 'top' &&
    css`
      margin-top: 6px;
      &:after {
        border-left: 8px solid transparent;
        border-bottom: 8px solid ${Styles.colors.neutral900};
        border-right: 8px solid transparent;
        top: -6px;
        left: ${arrowPlacement}px;
      }
    `}

  ${({ arrowDirection, arrowPlacement }) =>
    arrowDirection === 'bottom' &&
    css`
      margin-bottom: 6px;
      &:after {
        bottom: -6px;
        left: ${arrowPlacement}px;
        border-top: 8px solid ${Styles.colors.neutral900};
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
    `}

  ${({ arrowDirection, arrowPlacement }) =>
    arrowDirection === 'left' &&
    css`
      margin-left: 6px;
      &:after {
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid ${Styles.colors.neutral900};
        left: -6px;
        top: ${arrowPlacement}px;
      }
    `}

  ${({ arrowDirection, arrowPlacement }) =>
    arrowDirection === 'right' &&
    css`
      margin-right: 6px;
      &:after {
        border-left: 8px solid ${Styles.colors.neutral900};
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        right: -6px;
        top: ${arrowPlacement}px;
      }
    `}
`;

const TextWrapper = Styled.div`
  &, p {
    font-size: ${Styles.fontSizes.s2};
    color: ${Styles.colors.text.lightPrimary};
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-family: ${Styles.fonts.primary};
    margin: 0;
    -webkit-font-smoothing: inherit;
  }
`;

interface ITooltipProps {
  text: string | ReactElement<HTMLParagraphElement>;
  arrowDirection?: 'top' | 'right' | 'bottom' | 'left' | '';
  dataTestId?: string;
  visible?: boolean;
  arrowPlacement?: number;
  width?: string;
  maxWidth?: string;
}

export const Tooltip: FunctionComponent<ITooltipProps> = ({
  text,
  arrowDirection = '',
  dataTestId = 'tooltip',
  arrowPlacement,
  visible,
  width,
  maxWidth,
}) => {
  const [arrowPosition, setArrowPosition] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setArrowPosition(calcArrowPosition());
  }, [arrowPlacement, arrowDirection, width, maxWidth]);

  const calcArrowPosition = () => {
    if (!ref.current) {
      return 0;
    }

    const x = ref.current.offsetWidth - 16;
    const y = ref.current.offsetHeight - 16;
    const isVertical = arrowDirection === 'top' || arrowDirection === 'bottom';
    const isHorizontal =
      arrowDirection === 'left' || arrowDirection === 'right';

    // When no value is passed in, arrow will be centered
    if (!arrowPlacement) {
      const centerX = x / 2;
      const centerY = y / 2;
      return isVertical ? centerX : centerY;
    }

    const isNegative = arrowPlacement < 0;

    if (isNegative) {
      return 0;
    }

    if (isVertical) {
      return arrowPlacement > x ? x : arrowPlacement;
    }

    if (isHorizontal) {
      return arrowPlacement > y ? y : arrowPlacement;
    }
    return 0;
  };

  return (
    <TooltipContainer
      ref={ref}
      visible={visible}
      data-testid={dataTestId}
      arrowPlacement={arrowPosition}
      arrowDirection={arrowDirection}
      width={width}
      maxWidth={maxWidth}
    >
      <TextWrapper data-testid={`${dataTestId}-content`}>{text}</TextWrapper>
    </TooltipContainer>
  );
};

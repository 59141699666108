import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Styles } from '../../util/Styles';

interface ITablePopoverProps {
  width?: string;
  top?: number;
  right?: number;
}

const Wrapper = styled.div<ITablePopoverProps>`
  width: 260px;
  background-color: ${Styles.colors.white};
  border-radius: ${Styles.borderRadius.s};
  box-shadow: 0 3px 6px ${Styles.colors.text.darkDisabled};
  position: absolute;
  top: 36px;
  right: 2px;
  z-index: 2;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}
  ${({ right }) =>
    right &&
    css`
      right: ${right + 2}px;
    `}
`;

export const TablePopover: FunctionComponent<ITablePopoverProps> = ({
  children,
  width,
  top,
  right,
}) => {
  return (
    <Wrapper data-testid="table-popover" width={width} top={top} right={right}>
      {children}
    </Wrapper>
  );
};

import React, { FunctionComponent, useState, useEffect } from 'react';
import { Styles } from '../util/Styles';
import Styled, { css } from 'styled-components';
import { RadioButton } from './RadioButton';

export interface IRadioOption {
  label: string;
  id: string;
  tooltip?: string;
  disabled?: boolean;
}

interface IRadioButtonGroupProps {
  dataTestId?: string;
  onChange: (string) => void;
  label: string;
  options: IRadioOption[];
  maxWidth?: string;
  horizontal?: boolean;
  defaultSelected?: string;
}

const Fieldset = Styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

const Legend = Styled.legend`
  font-family: ${Styles.fonts.primary};
  font-weight: ${Styles.fontWeights.medium};
  font-size: ${Styles.fontSizes.base};
  margin-left: 8px;
`;

interface IGridProps {
  horizontal: boolean;
}

const Grid = Styled.div<IGridProps>`
  display: grid;
  margin-top: 4px;

  ${({ horizontal }) =>
    horizontal &&
    css`
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
`;

export const RadioButtonGroup: FunctionComponent<IRadioButtonGroupProps> = ({
  label,
  onChange,
  options,
  maxWidth = '400px',
  dataTestId = `radio-button-group`,
  horizontal = false,
  defaultSelected = '',
}) => {
  const [selectedItem, setSelectedItem] = useState(defaultSelected);

  useEffect(() => {
    setSelectedItem(defaultSelected);
  }, [defaultSelected]);

  const handleClick = (id: string) => {
    if (id !== selectedItem) {
      onChange(id);
      setSelectedItem(id);
    }
  };

  const renderRadioButtons = options.map(({ id, disabled, label, tooltip }) => {
    let isChecked = id === selectedItem;

    return (
      <RadioButton
        onChange={handleClick}
        isSelected={isChecked}
        label={label}
        disabled={disabled}
        key={id}
        id={id}
        dataTestId={id}
        tooltip={tooltip}
      />
    );
  });

  return (
    <Fieldset data-testid={dataTestId}>
      <Legend>{label}</Legend>
      <Grid maxWidth={maxWidth} horizontal={horizontal} role="radiogroup">
        {renderRadioButtons}
      </Grid>
    </Fieldset>
  );
};

import React, { FunctionComponent, Fragment, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';

const Body = styled.div`
  height: 202px;
  width: 260px;
  overflow-y: hidden;
  padding: 16px 8px;
  box-sizing: border-box;
`;

const ScrollableBody = styled.div`
  display: grid;
  grid-row-gap: 8px;
  overflow-y: auto;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  border-top: 1px solid #cadae6;
  padding: 16px;
  box-sizing: border-box;
`;

interface IHideColumnsTablePopover {
  columns: any;
  onApply: (selectedOptions: string[]) => void;
  onReset: () => void;
  hiddenColumns: string[];
}
export const HideColumnsTablePopover: FunctionComponent<IHideColumnsTablePopover> = ({
  columns,
  onApply,
  onReset,
  hiddenColumns,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(hiddenColumns);
  const isApplyDisabled = columns.length === selectedOptions.length;
  const updateSelectedOptions = (id: string) => () => {
    const hasBeenSelected = selectedOptions.filter((item) => item === id)
      .length;

    if (hasBeenSelected) {
      const updatedSelectedOptions = selectedOptions.filter(
        (item) => item !== id
      );
      return setSelectedOptions(updatedSelectedOptions);
    }
    return setSelectedOptions((selectedOptions) => [...selectedOptions, id]);
  };

  const renderOptions = () => {
    return columns.map(({ label, hideDisabled, id }) => {
      const isHidden = !!selectedOptions.filter((item) => item === id).length;
      return (
        <Checkbox
          dataTestId={`${id}-checkbox`}
          key={id}
          label={label}
          disabled={hideDisabled}
          isDefaultChecked={!isHidden}
          onChange={updateSelectedOptions(id)}
        />
      );
    });
  };

  const handleApply = () => {
    onApply(selectedOptions);
  };

  return (
    <div data-testid="hideColumnsPopover">
      <Body>
        <ScrollableBody>{renderOptions()}</ScrollableBody>
      </Body>
      <ButtonWrapper>
        <Button
          label="Reset"
          type="secondary"
          onClick={onReset}
          dataTestId="popoverResetButton"
        />
        <Button
          label="Apply"
          type="primary"
          onClick={handleApply}
          dataTestId="popoverApplyButton"
          disabled={isApplyDisabled}
        />
      </ButtonWrapper>
    </div>
  );
};

import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Styles } from '../util/Styles';
import Styled, { css } from 'styled-components';
import { Icon } from './Icon';
import { DropdownOptions, DropdownItem } from './dropdown/DropdownOptions';

export interface DropdownProps {
  label: string;
  onClick: Function;
  items: DropdownItem[];
  disabled?: boolean;
  dataTestId?: string;
  width?: string;
  placeholder?: string;
  popoverWidth?: string;
  popoverHeight?: string;
  minWidth?: string;
  maxWidth?: string;
  multiSelect?: boolean;
  form?: boolean;
  id?: string;
}

interface OverlayProps {
  active: boolean;
}

const Overlay = Styled.div<OverlayProps>`
  display: block;
  position: fixed;
  width: 100%;
  height:100%;
  top: 0;
  left: 0;
  z-index: 1;
  ${({ active }) =>
    !active &&
    css`
      display: none;
    `}
`;

interface WrapperProps {
  maxWidth: string;
  minWidth: string;
  width: number;
  formStyle: boolean;
}

const Wrapper = Styled.div<WrapperProps>`
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ formStyle }) =>
    formStyle &&
    css`
      display: grid;
    `}
`;

interface ButtonWrapperProps {
  disabled: boolean;
  formStyle: boolean;
  isOpen: boolean;
}

const ButtonWrapper = Styled.button<ButtonWrapperProps>`
  position: relative;
  display: grid;
  grid-template-columns: auto 2fr auto;
  border-radius: ${Styles.borderRadius.s};
  padding: 1px 12px;
  margin: 0;
  border-radius: 4px;
  height: 38px;
  outline: 0;
  font-family: ${Styles.fonts.primary};
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  background-color: ${Styles.colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${Styles.colors.neutral300};
  transition: all 0.2s ease;
  user-select: none;

  ${({ formStyle }) =>
    formStyle &&
    css`
      grid-template-columns: 2fr auto;
      margin-top: 8px;
      height: 45px;
      border-width: 2px;
      width: 100%;
    `}

  &:hover {
    background-color: ${Styles.colors.neutral100};
    ${({ formStyle }) =>
      formStyle &&
      css`
        background: 0;
        border-color: ${Styles.colors.blue500};
      `}
  }
  
  &:focus {
    background-color: ${Styles.colors.neutral100};

    ${({ formStyle }) =>
      formStyle &&
      css`
        background: 0;
        border: 2px solid ${Styles.colors.blue500};
      `}
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: ${Styles.colors.neutral100};

      ${({ formStyle }) =>
        formStyle &&
        css`
          background: 0;
          border: 2px solid ${Styles.colors.blue500};
        `}
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${Styles.colors.neutral100};
      border-color: ${Styles.colors.neutral300};
      border: 1px solid ${Styles.colors.neutral300};

      &:hover {
        background-color: ${Styles.colors.neutral100};
        border-color: ${Styles.colors.neutral300};
      }
    `}

`;

const LabelWrapper = Styled.label`
  text-transform: uppercase;
  font-family: ${Styles.fonts.primary};
  color: ${Styles.colors.neutral700};
  font-size: ${Styles.fontSizes.s1};
  align-self: center;
  padding-top: 1px;
  margin: 0 8px 0 0;
  text-align: left;
  font-weight: 500;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${Styles.colors.neutral300};
    `}
`;

interface FormLabelWrapperProps {
  isOpen: boolean;
  maxWidth: string;
  width: string;
}

const FormLabelWrapper = Styled.label<FormLabelWrapperProps>`
  display: inline-block;
  font-family: ${Styles.fonts.primary};
  color: ${Styles.colors.text.darkSecondary};
  font-size: ${Styles.fontSizes.s3};
  text-align: left;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  &:focus {
    color: ${Styles.colors.blue500};
  }
  
  ${({ isOpen }) =>
    isOpen &&
    css`
      color: ${Styles.colors.blue500};
    `}
  
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

interface ValueWrapperProps {
  formStyle: boolean;
  isPlaceholder: boolean;
}

const ValueWrapper = Styled.span<ValueWrapperProps>`
  font-family: ${Styles.fonts.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${Styles.fontSizes.s3};
  align-self: center;
  text-align: end;
  justify-self: end;
  width: 100%;

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    css`
      color: ${Styles.colors.text.darkPlaceholder};
    `}

  ${({ formStyle }) =>
    formStyle &&
    css`
      text-align: start;
      justify-self: start;
      margin-left: 4px;
      font-size: ${Styles.fontSizes.m1};
      line-height: calc(${Styles.fontSizes} + 1px);
    `}
`;

const IconWrapper = Styled.span`
  transition: all 0.8s ease;
  opacity: 0.5;
  margin-left: 12px;
  align-self: center;
  justify-self: end;
`;

export interface DropdownItemsProps {
  onClick: Function;
  items: DropdownItem[];
  multiSelect: boolean;
  defaultSelectedItems?: DropdownItem[];
}

interface OptionsWrapperProps {
  formStyle: boolean;
  width?: string;
}

const OptionsWrapper = Styled.div<OptionsWrapperProps>`
  position: absolute;
  top: 46px;
  left: -1px;
  z-index: 3;
  ${({ formStyle }) =>
    formStyle &&
    css`
      top: 46px;
    `}
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

export const Dropdown: FunctionComponent<DropdownProps> = ({
  label,
  onClick,
  items,
  id = 'dropdown',
  multiSelect = false,
  disabled = false,
  form = false,
  dataTestId = 'dropdown',
  width = '100%',
  minWidth = '90px',
  maxWidth = '280px',
  popoverWidth = null,
  popoverHeight = '220px',
  placeholder = 'Select a value...',
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const hasPlaceholderText = selectedItems.length === 0;

  useEffect(() => {
    const defaultSelectedItems = items.filter(({ isSelected }) => isSelected);
    if (!multiSelect) {
      setSelectedItems([defaultSelectedItems[0]]);
      return;
    }
    setSelectedItems(defaultSelectedItems);
  }, [items, multiSelect]);

  const handleSelect = (item: DropdownItem) => {
    if (multiSelect) {
      let currentItems = [...selectedItems];
      const isCurrentItem = !!currentItems.find(({ id }) => id === item.id);
      if (isCurrentItem) {
        currentItems = currentItems.filter(({ id }) => id !== item.id);
      } else {
        currentItems.push(item);
      }

      setSelectedItems(currentItems);
      onClick(currentItems);
      return;
    }

    setIsOpen(false);
    setSelectedItems([item]);
    onClick(item);
  };

  const renderLabel = () => {
    return (
      !form && (
        <LabelWrapper htmlFor={id} disabled={disabled}>
          {label}
        </LabelWrapper>
      )
    );
  };

  const renderFormLabel = () => {
    return (
      form && (
        <FormLabelWrapper
          htmlFor={id}
          disabled={disabled}
          isOpen={isOpen}
          maxWidth={maxWidth}
          width={width}
        >
          {label}
        </FormLabelWrapper>
      )
    );
  };

  const calcText = () => {
    if (selectedItems.length > 1) {
      return 'Multiple';
    }

    return selectedItems[0] ? selectedItems[0].value : placeholder;
  };

  const renderIcon = () => {
    let iconName = 'faAngleDown';
    let ariaLabel = 'menu closed';
    if (isOpen) {
      iconName = 'faAngleUp';
      ariaLabel = 'menu open';
    }
    return (
      <IconWrapper>
        <Icon name={iconName} ariaLabel={ariaLabel}></Icon>
      </IconWrapper>
    );
  };

  const handleToggleDropdown = (open: boolean) => () => {
    if (open !== isOpen) {
      setIsOpen(open);
    }
  };

  const renderOptions = () => {
    if (!isOpen) return;

    const dropdownOptionsPadding = !!form ? '12px' : '8px';
    const dropdownOptionsWidth = popoverWidth || (form && '100%') || null;
    const itemsWithCurrentSelected = items.map((item) => ({
      isSelected: selectedItems.includes(item),
      ...item,
    }));

    return (
      <OptionsWrapper formStyle={form} width={dropdownOptionsWidth}>
        <DropdownOptions
          items={itemsWithCurrentSelected}
          onValueChange={handleSelect}
          width={dropdownOptionsWidth}
          height={popoverHeight}
          multiSelect={multiSelect}
          sidePadding={dropdownOptionsPadding}
          dataTestId={`${dataTestId}-options`}
        />
      </OptionsWrapper>
    );
  };
  return (
    <Fragment>
      <Overlay active={isOpen} onClick={handleToggleDropdown(false)} />
      <Wrapper
        width={width}
        minWidth={minWidth}
        maxWidth={maxWidth}
        formStyle={form}
      >
        {renderFormLabel()}
        <ButtonWrapper
          data-testid={dataTestId}
          onClick={handleToggleDropdown(true)}
          disabled={disabled}
          isOpen={isOpen}
          formStyle={form}
        >
          {renderLabel()}
          <ValueWrapper
            width={width}
            formStyle={form}
            isPlaceholder={hasPlaceholderText}
          >
            {calcText()}
          </ValueWrapper>
          {renderIcon()}
          {renderOptions()}
        </ButtonWrapper>
      </Wrapper>
    </Fragment>
  );
};

import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Styles } from '../util/Styles';
import { TextField } from './TextField';
import { Icon } from './Icon';
import { getDaysOfMonth, getStartDay } from '../util/date';
import { select } from '@storybook/addon-knobs';

interface IOverlayProps {
  active: boolean;
}

const Overlay = styled.div<IOverlayProps>`
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  ${({ active }) =>
    !active &&
    css`
      display: none;
    `}
`;

interface IWrapperProps {
  width: string;
}

const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  z-index: 2;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;
interface IPopoverProps {
  active: boolean;
}

const Animation = keyframes`
    0% {
        opacity: 0;
        display: none;
    }
    20% {
        display: block;
    }
    100% {
        opacity: 1;
    }
`;

const Popover = styled.div<IPopoverProps>`
  display: none;
  width: 300px;
  height: 304px;
  background: ${Styles.colors.white};
  box-shadow: 0px 1px 10px #00000033;
  border-radius: ${Styles.borderRadius.s};
  transform: scale(0);
  transition: 0.2s;
  opacity: 0;
  position: absolute;
  ${({ active }) =>
    active &&
    css`
      animation-name: ${Animation};
      animation-iteration-count: 1;
      animation-duration: 0.3s;
      animation-direction: alternate;
      display: block;
      opacity: 1;
      transform: scale(1);
    `}
  padding: 24px;
  box-sizing: border-box;
`;

const PopoverHeader = styled.div`
  display: grid;
  grid-template-columns: 30px auto 30px;
`;

const PopoverMonth = styled.div`
  font-family: ${Styles.fonts.primary};
  font-size: ${Styles.fontSizes.m1};
  justify-self: center;
  align-self: center;
  color: ${Styles.colors.text.darkPrimary};
`;

const PopoverDay = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding-top: 16px;
  grid-column-gap: 8px;
  text-align: center;
  text-transform: uppercase;
  font-size: ${Styles.fontSizes.s1};
`;

const DayHeader = styled.div`
  font-family: ${Styles.fonts.primary};
  color: ${Styles.colors.text.darkPlaceholder};
  padding-bottom: 8px;
`;

const PopoverBody = styled.div`
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-template-rows: repeat(5, 30px);
  grid-row-gap: 3px;
`;
interface IPopoverBodyItemProps {
  gridColumn: number;
  notCurrentMonth: boolean;
}

const PopoverBodyItem = styled.div<IPopoverBodyItemProps>`
  font-family: ${Styles.fonts.primary};
  font-size: ${Styles.fontSizes.s2};
  text-align: center;

  ${({ gridColumn }) =>
    gridColumn &&
    css`
      grid-column: ${gridColumn};
    `}
  ${({ notCurrentMonth }) =>
    notCurrentMonth &&
    css`
      color: ${Styles.colors.neutral300};
    `}
`;
interface IDateButtonProps {
  notCurrentMonth: boolean;
  isActive: boolean;
}

const DateButton = styled.button<IDateButtonProps>`
  border: 0;
  height: 32px;
  width: 32px;
  background-color: ${Styles.colors.white};
  border-radius: ${Styles.borderRadius.s};
  cursor: pointer;
  transition: all 70ms ease;
  &:hover,
  &:focus {
    background: ${Styles.colors.blue500};
    color: ${Styles.colors.white};
  }
  ${({ notCurrentMonth }) =>
    notCurrentMonth &&
    css`
      color: ${Styles.colors.neutral300};
      &:hover,
      &:focus {
        color: ${Styles.colors.neutral500};
        background-color: ${Styles.colors.white};
      }
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${Styles.colors.blue500};
      color: ${Styles.colors.white};
    `}
`;

const IconButton = styled.button`
  padding: 5px 10px;
  border-radius: ${Styles.borderRadius.s};
  display: grid;
  align-items: center;
  justify-content: center;
  background: ${Styles.colors.neutral100};
  color: ${Styles.colors.text.darkSecondary};
  border: 0;
  justify-self: center;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: ${Styles.colors.neutral300};
  }
`;

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

interface IProps {
  label: string;
  placeholder?: string;
  width?: string;
  dataTestId?: string;
  errorText?: string;
  onDateChange: (date: string) => void;
  tooltip?: string;
  defaultValue?: string;
  required?: boolean;
}

export const DatePicker: FunctionComponent<IProps> = ({
  label,
  placeholder = 'Jan 01 2020',
  width,
  dataTestId = 'datepicker',
  errorText = '',
  onDateChange,
  tooltip,
  defaultValue,
  required,
}) => {
  const todaysDate = new Date();
  const thisMonth = todaysDate.getMonth();
  const thisYear = todaysDate.getFullYear();
  const daysOfMonth = getDaysOfMonth(thisYear, thisMonth);
  const startDay = getStartDay(thisYear, thisMonth);
  const [state, setState] = useState({
    currentDay: 0,
    currentMonth: thisMonth,
    currentYear: thisYear,
    currentDaysOfMonth: daysOfMonth,
    currentStartDay: startDay,
    selectedDate: '',
    fieldValue: '',
    showError: true,
    errorMessage: '',
  });
  const {
    currentDay,
    currentMonth,
    currentYear,
    currentStartDay,
    currentDaysOfMonth,
    selectedDate,
    fieldValue,
    showError,
    errorMessage,
  } = state;
  const [active, setActive] = useState(false);

  useEffect(() => {
    const ISO = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

    if (ISO.test(defaultValue)) {
      const splitDate = defaultValue.split('-');
      const year = parseInt(splitDate[0]);
      const month = parseInt(splitDate[1]) - 1;
      const day = parseInt(splitDate[2].substr(0, 2));
      const daysOfMonth = getDaysOfMonth(year, month);
      const startDay = getStartDay(year, month);

      setState({
        ...state,
        selectedDate: defaultValue,
        currentYear: year,
        currentMonth: month,
        currentDay: day,
        currentDaysOfMonth: daysOfMonth,
        currentStartDay: startDay,
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!selectedDate.length && state.fieldValue.length) {
      setState({
        ...state,
        fieldValue: '',
      });
    }

    if (selectedDate.length) {
      const value = `${months[currentMonth].substring(
        0,
        3
      )} ${currentDay} ${currentYear}`;
      setState({
        ...state,
        fieldValue: value,
      });
    }

    onDateChange(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (active) {
      document.addEventListener('keydown', ({ key }) => {
        if (key === 'Escape') setActive(false);
      });
      return setState({
        ...state,
        showError: false,
      });
    }
    return document.removeEventListener('keydown', () => null);
  }, [active]);

  const handleFieldChange = (val: string) => {
    let month, day, year;

    // GET MONTH
    const findMonth = (mon) =>
      mon.substr(0, 3).toUpperCase() ===
      val.split(' ')[0].substr(0, 3).toUpperCase();
    month = months.findIndex(findMonth);
    month = month >= 0 ? month : NaN;

    // GET DAY
    day = val && val.split(' ')[1] ? val.split(' ')[1] : NaN;
    day = day <= getDaysOfMonth(currentYear, currentMonth) ? day : NaN;

    //GET YEAR
    year =
      val.length && val.split(' ')[2] && val.split(' ')[2].length === 4
        ? val.split(' ')[2].substr(0, 4)
        : NaN;

    if (!val.length) {
      return setState({
        ...state,
        errorMessage: '',
        selectedDate: '',
      });
    }

    if (isNaN(month) || isNaN(day) || isNaN(year)) {
      return setState({
        ...state,
        errorMessage: 'Please enter a valid date eg. Jan 01 2020',
        showError: false,
        fieldValue: '',
      });
    }

    return setState({
      ...state,
      currentDay: parseInt(day),
      currentMonth: parseInt(month),
      currentYear: parseInt(year),
      currentStartDay: getStartDay(year, month),
      selectedDate: new Date(year, month, day).toISOString(),
      errorMessage: '',
    });
  };

  const handleBlur = () => {
    setState({
      ...state,
      showError: true,
    });
  };

  const handleActive = (toggle: boolean) => () => {
    setActive(toggle);
  };

  const handleMonthChange = (method: string) => () => {
    if (method === 'prev') {
      if (currentMonth === 0) {
        return setState({
          ...state,
          currentMonth: 11,
          currentYear: currentYear - 1,
          currentDaysOfMonth: getDaysOfMonth(currentYear - 1, 11),
          currentStartDay: getStartDay(currentYear - 1, 11),
        });
      }

      return setState({
        ...state,
        currentDaysOfMonth: getDaysOfMonth(currentYear, currentMonth - 1),
        currentStartDay: getStartDay(currentYear, currentMonth - 1),
        currentMonth: currentMonth - 1,
      });
    }

    if (method === 'next') {
      if (currentMonth === 11) {
        return setState({
          ...state,
          currentMonth: 0,
          currentDaysOfMonth: getDaysOfMonth(currentYear + 1, 0),
          currentStartDay: getStartDay(currentYear + 1, 0),
          currentYear: currentYear + 1,
        });
      }
      return setState({
        ...state,
        currentDaysOfMonth: getDaysOfMonth(currentYear, currentMonth + 1),
        currentStartDay: getStartDay(currentYear, currentMonth + 1),
        currentMonth: currentMonth + 1,
      });
    }
  };

  const handleSelectDate = (position: string, day?: number) => () => {
    setActive(false);

    if (position === 'previous') {
      if (currentMonth === 0) {
        return setState({
          ...state,
          currentMonth: 11,
          currentYear: currentYear - 1,
          currentDaysOfMonth: getDaysOfMonth(currentYear - 1, 11),
          currentStartDay: getStartDay(currentYear - 1, 11),
          selectedDate: new Date(currentYear - 1, 11, day).toISOString(),
          currentDay: day,
          errorMessage: '',
        });
      }
      return setState({
        ...state,
        currentMonth: currentMonth - 1,
        currentDaysOfMonth: getDaysOfMonth(currentYear, currentMonth - 1),
        currentStartDay: getStartDay(currentYear, currentMonth - 1),
        selectedDate: new Date(
          currentYear,
          currentMonth - 1,
          day
        ).toISOString(),
        currentDay: day,
        errorMessage: '',
      });
    }

    if (position === 'next') {
      if (currentMonth === 11) {
        return setState({
          ...state,
          currentMonth: 0,
          currentYear: currentYear + 1,
          currentDaysOfMonth: getDaysOfMonth(currentYear + 1, 0),
          currentStartDay: getStartDay(currentYear + 1, 0),
          selectedDate: new Date(currentYear + 1, 0, day).toISOString(),
          currentDay: day,
          errorMessage: '',
        });
      }
      return setState({
        ...state,
        currentMonth: currentMonth + 1,
        currentDaysOfMonth: getDaysOfMonth(currentYear, currentMonth + 1),
        currentStartDay: getStartDay(currentYear, currentMonth + 1),
        currentDay: day,
        selectedDate: new Date(
          currentYear,
          currentMonth + 1,
          day
        ).toISOString(),
        errorMessage: '',
      });
    }
    setState({
      ...state,
      selectedDate: new Date(currentYear, currentMonth, day).toISOString(),
      currentDay: day,
      errorMessage: '',
    });
  };

  const renderDayHeader = () => {
    return days.map((day) => {
      return <DayHeader key={day}>{day}</DayHeader>;
    });
  };

  const renderDays = () => {
    let content = [];
    let previous = [];
    const daysOfPreviousMonth =
      currentMonth === 0
        ? getDaysOfMonth(currentYear, 11)
        : getDaysOfMonth(currentYear, currentMonth - 1);

    if (currentStartDay !== 0) {
      for (let i = 0; i < currentStartDay; i++) {
        previous.push(daysOfPreviousMonth - i);
      }
      previous.reverse().forEach((date, index) => {
        content.push(
          <PopoverBodyItem
            notCurrentMonth
            gridColumn={index + 1}
            key={`previous-${index}`}
          >
            <DateButton
              onClick={handleSelectDate('previous', date)}
              notCurrentMonth
            >
              {date}
            </DateButton>
          </PopoverBodyItem>
        );
      });
    }

    for (let i = 1; i <= currentDaysOfMonth; i++) {
      const dayOfDate = new Date(currentYear, currentMonth, i).getDay();
      const selectedMonth = parseInt(selectedDate.split('-')[1]) - 1;
      const selectedYear = parseInt(selectedDate.split('-')[0]);
      const isActive =
        currentDay === i &&
        currentMonth === selectedMonth &&
        currentYear === selectedYear;
      content.push(
        <PopoverBodyItem gridColumn={dayOfDate + 1} key={`current-${i}`}>
          <DateButton
            data-testid={`${i}-${currentMonth + 1}-${currentYear}`}
            isActive={isActive}
            onClick={handleSelectDate('', i)}
          >
            {i}
          </DateButton>
        </PopoverBodyItem>
      );
    }

    const afterMonth = 42 - content.length;

    if (afterMonth) {
      for (let i = 0; i < afterMonth; i++) {
        content.push(
          <PopoverBodyItem notCurrentMonth key={`next-${i}`}>
            <DateButton
              notCurrentMonth
              onClick={handleSelectDate('next', i + 1)}
            >
              {i + 1}
            </DateButton>
          </PopoverBodyItem>
        );
      }
    }
    return content;
  };

  const displayError = !active && showError;
  const error = displayError
    ? errorMessage.length
      ? errorMessage
      : errorText
    : '';

  return (
    <Fragment>
      <Overlay active={active} onClick={handleActive(false)} />
      <Wrapper width={width} data-testid={dataTestId}>
        <TextField
          label={label}
          placeholder={placeholder}
          icon="faCalendar"
          iconPosition="right"
          onFocus={handleActive(true)}
          dataTestId="datepickerTextfield"
          defaultValue={fieldValue}
          onChange={handleFieldChange}
          errorText={error}
          onBlur={handleBlur}
          tooltip={tooltip}
          required={required}
        />
        <Popover
          active={active}
          data-testid={`calendar${active ? '-active' : ''}`}
        >
          <PopoverHeader>
            <IconButton onClick={handleMonthChange('prev')}>
              <Icon name="faCaretLeft" ariaLabel="Previous month" />
            </IconButton>
            <PopoverMonth>
              {months[currentMonth]} {currentYear}
            </PopoverMonth>
            <IconButton onClick={handleMonthChange('next')}>
              <Icon name="faCaretRight" ariaLabel="Next month" />
            </IconButton>
          </PopoverHeader>

          <PopoverDay>{renderDayHeader()}</PopoverDay>

          <PopoverBody>{renderDays()}</PopoverBody>
        </Popover>
      </Wrapper>
    </Fragment>
  );
};

import React, { FunctionComponent, useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useVisibilityAnimation } from '../util/Hooks';
import { Styles } from '../util/Styles';

interface IDrawerProps {
  width?: string;
  height?: string;
  visible: boolean;
  dataTestId?: string;
}

interface IWrapperProps {
  width?: string;
  height?: string;
  visible: boolean;
  dataTestId?: string;
  animation: string;
}

const moveInAnimation = () => keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(calc(0px - 100% - 5px)); }
`;

const moveOutAnimation = () => keyframes`
  0% { transform: translateY(calc(0px - 100% - 5px)); }
  100% { transform: translateY(0); }
`;

const Wrapper = styled.div<IWrapperProps>`
  position: absolute;
  left: 0;
  overflow: hidden;
  background-color: ${Styles.colors.white};
  box-shadow: 0 0 6px ${Styles.colors.text.darkDisabled};
  padding: 12px;
  box-sizing: border-box;
  display: grid;
  width: ${({ width }) => width || '100%'};
  ${({ animation }) =>
    animation &&
    css`
      -webkit-animation: ${animation} 0.3s ease forwards;
      animation: ${animation} 0.3s ease forwards;
    `}
  ${({ height }) =>
    height &&
    css`
      height: ${height};
      bottom: calc(0px - ${height} - 5px);
    `}
`;

export const Drawer: FunctionComponent<IDrawerProps> = ({
  children,
  width = '100%',
  height = '72px',
  visible = false,
  dataTestId = 'drawer',
}) => {
  const [animation, setAnimation] = useState(null);
  const [display, setVisibility] = useVisibilityAnimation(300, visible);

  useEffect(() => {
    setVisibility(visible);

    if (visible) {
      return setAnimation(moveInAnimation());
    }

    if (animation) {
      setAnimation(moveOutAnimation());
    }
  }, [visible]);

  return (
    <Wrapper
      width={width}
      height={height}
      visible={visible}
      data-testid={dataTestId}
      animation={animation}
      style={display}
    >
      {children}
    </Wrapper>
  );
};

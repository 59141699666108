import React, { FunctionComponent } from 'react';
import { useHover } from '../util/Hooks';
import { Styles } from '../util/Styles';
import Styled, { css } from 'styled-components';

interface IWrapperProps {
  dataTestId: string;
  onClick: (e: Event) => void;
  isSelected: boolean;
  isDivider?: boolean;
  isHovered?: boolean;
  disabled?: boolean;
  width: number;
  fontSize: number;
}

const Wrapper = Styled.button<IWrapperProps>`
  display: inline-block;
  vertical-align: top;
  height: 32px;
  margin: auto;
  background-color: ${Styles.colors.neutral100};
  border: 2px;
  border-color: ${Styles.colors.neutral300};
  border-radius: ${Styles.borderRadius.s};
  font-weight: ${Styles.fontWeights.medium};
  color: ${Styles.colors.neutral900};
  font-family: ${Styles.fonts.primary};
  line-height: 1;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}

  ${({ isHovered, disabled }) =>
    isHovered &&
    !disabled &&
    css`
      background-color: ${Styles.colors.neutral300};
      transition: background-color 0.1s ease-in;
    `}

  &:active {
    background-color: ${Styles.colors.blue500};
  }

  &:focus {
    position: relative;
  }


  ${({ isSelected }) =>
    isSelected &&
    css`
      cursor: default;
      transform: scale(1.1);
      background-color: ${Styles.colors.blue500};
      color: ${Styles.colors.white};
      vertical-align: unset;
    `}

  ${({ width, fontSize }) => `
    width: ${width}px;
    font-size: ${fontSize};
  `}
`;

interface IPaginationCellProps {
  onClick?: (e: Event) => void;
  isSelected?: boolean;
  dataTestId: string;
  disabled?: boolean;
  value: string;
}

export const PaginationCell: FunctionComponent<IPaginationCellProps> = ({
  isSelected,
  value,
  dataTestId,
  onClick,
  disabled = false,
}) => {
  const { isHovered, handleHover } = useHover();
  const ariaLabel = `${isSelected ? 'Current page' : 'Go to page'} ${value}`;
  const buttonWidth =
    value !== '...' && value.length > 2 ? (value.length - 2) * 8 + 32 : 32;
  const fontSize = buttonWidth > 32 ? Styles.fontSizes.s2 : Styles.fontSizes.s3;

  return (
    <Wrapper
      isHovered={isHovered}
      isSelected={isSelected}
      onClick={onClick}
      onMouseEnter={handleHover(true)}
      onMouseLeave={handleHover(false)}
      disabled={disabled}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      aria-current={isSelected}
      aria-disabled={disabled}
      width={buttonWidth}
      fontSize={fontSize}
    >
      {value}
    </Wrapper>
  );
};

import React, { FunctionComponent } from 'react';
import { Styles } from '../util/Styles';
import styled, { css } from 'styled-components';

interface IBadgeProps {
  color?: string;
  backgroundColor?: string;
  label: string;
  dataTestId?: string;
}

interface IWrapperProps {
  color: string;
  backgroundColor: string;
}

const Wrapper = styled.div<IWrapperProps>`
  text-transform: uppercase;
  padding: 4px 12px 4px 12px;
  border-radius: ${Styles.borderRadius.s};
  display: inline-block;
  overflow: hidden;
  text-align: center;
  line-height: 14px;
  color: ${Styles.colors.white};
  background-color: ${Styles.colors.blue500};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`;

const LabelWrapper = styled.div`
  font-family: ${Styles.fonts.primary};
  letter-spacing: 1px;
  font-weight: ${Styles.fontWeights.semibold};
  color: ${Styles.colors.white};
  font-size: ${Styles.fontSizes.s1};
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
`;

export const Badge: FunctionComponent<IBadgeProps> = ({
  label,
  color = Styles.colors.white,
  backgroundColor = Styles.colors.blue500,
  dataTestId = 'badge',
}) => {
  return (
    <Wrapper
      color={color}
      backgroundColor={backgroundColor}
      data-testid={dataTestId}
    >
      <LabelWrapper>{label}</LabelWrapper>
    </Wrapper>
  );
};

const BadgeGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 100px 100px 100px 100px;
  grid-column-gap: 16px;
`;

interface Iprops {
  colors: string[];
}

export const BadgeGrid: FunctionComponent<Iprops> = ({ colors }) => {
  const renderBadges = () => {
    return colors.map((color) => {
      return <Badge label="badge" backgroundColor={color} />;
    });
  };

  return <BadgeGridWrapper>{renderBadges()}</BadgeGridWrapper>;
};

import React, {
  FunctionComponent,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from 'react';
import styled, { css, keyframes } from 'styled-components';
import { TableHeader } from './table/TableHeader';
import { TablePopover } from './table/TablePopover';
import { HideColumnsTablePopover } from './templates/HideColumnsTablePopover';
import { ActionLinksTablePopover } from './templates/ActionLinksTablePopover';
import { EmptyTableTemplate } from './templates/emptyTableTemplate';
import { Icon } from './Icon';
import { Checkbox } from './Checkbox';
import { Styles } from '../util/Styles';
import { Tooltip } from './Tooltip';
import { useHover } from '../util/Hooks';

interface IScrollWrapperProps {
  width?: string;
  hideShadow?: boolean;
}

interface IWrapperProps {
  maxHeight?: string;
}

const Container = styled.div`
  position: relative;
`;

const ScrollWrapper = styled.div<IScrollWrapperProps>`
  position: relative;
  overflow: auto;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ hideShadow }) =>
    !hideShadow &&
    css`
      box-shadow: 0px 3px 6px #00000029;
    `}
`;

const Wrapper = styled.div<IWrapperProps>`
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}
`;

const TableWrapper = styled.table`
  border: 0;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`;

interface ICellProps {
  isFixed: boolean;
  leftPosition?: number;
  rightBorder?: boolean;
  rightFixed?: boolean;
  isSelected?: boolean;
}

const Cell = styled.td<ICellProps>`
  padding: 12px 20px 12px 10px;
  font-family: ${Styles.fonts.primary};
  background: white;
  background-clip: padding-box;
  box-sizing: border-box;
  ${({ isFixed, leftPosition, isSelectable }) =>
    isFixed &&
    css`
      position: sticky;
      left: ${isSelectable
        ? leftPosition + 49
        : leftPosition
        ? leftPosition
        : 0}px;
      z-index: 1;
    `}

  ${({ rightFixed }) =>
    rightFixed &&
    css`
      position: sticky;
      right: 0;
      &:after {
        height: calc(100% + 1px);
        content: '';
        width: 10px;
        background: transparent
          linear-gradient(-90deg, #22272b 0%, #22272b00 100%) 0% 0% no-repeat
          padding-box;
        left: 0;
        position: absolute;
        top: 0;
        opacity: 0.15;
      }
    `}

    ${({ rightBorder }) =>
    rightBorder &&
    css`
      &:after {
        height: calc(100% + 1px);
        content: '';
        width: 10px;
        background: transparent
          linear-gradient(90deg, #22272b 0%, #22272b00 100%) 0% 0% no-repeat
          padding-box;
        right: 0;
        position: absolute;
        top: 0;
        opacity: 0.15;
      }
    `}

    ${({ isSelected }) =>
    isSelected &&
    css`
      background: ${Styles.colors.neutral50};
    `}
`;

const Row = styled.tr`
  border-bottom: 1px solid ${Styles.colors.neutral300};
  padding: 20px 0;
`;

interface IFixedRight {
  isFixed: boolean;
  overlayActive?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
}

const HideColumn = styled.th<IFixedRight>`
  background-color: ${Styles.colors.neutral100};
  padding: 10px;
  box-sizing: border-box;
  color: #788a9a;
  font-size: 14px;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 50px;
  ${({ isFixed }) =>
    isFixed &&
    css`
      position: sticky;
      right: 0;
      padding-left: 20px;
      &:after {
        height: calc(100% + 1px);
        content: '';
        width: 10px;
        background: transparent
          linear-gradient(-90deg, #22272b 0%, #22272b00 100%) 0% 0% no-repeat
          padding-box;
        left: 0;
        position: absolute;
        top: 0;
        opacity: 0.15;
      }
      ${({ overlayActive }) =>
        overlayActive &&
        css`
          z-index: 2;
        `}
    `}
`;

const ActionCell = styled.td<IFixedRight>`
  text-align: center;
  color: #757575;
  background-color: ${Styles.colors.white};
  background-clip: padding-box;
  ${({ isFixed }) =>
    isFixed &&
    css`
      position: sticky;
      right: 0;
      &:before {
        height: calc(100% + 1px);
        content: '';
        width: 10px;
        background: transparent
          linear-gradient(-90deg, #22272b 0%, #22272b00 100%) 0% 0% no-repeat
          padding-box;
        left: 0;
        position: absolute;
        top: 0;
        opacity: 0.15;
      }
      padding-left: 10px;
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: ${Styles.colors.neutral50};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      > span {
        color: ${Styles.colors.neutral300};
      }
    `}
`;

const HeaderRow = styled.tr`
  background-color: ${Styles.colors.neutral100};
  th {
    border-top: 1px solid ${Styles.colors.neutral300};
    border-bottom: 1px solid ${Styles.colors.neutral300};
  }
  th:first-child {
    border-left: 1px solid ${Styles.colors.neutral300};
  }
  th:last-child {
    border-right: 1px solid ${Styles.colors.neutral300};
  }
`;

interface ISelectAllProps {
  isFixed: boolean;
}

const SelectAll = styled.th<ISelectAllProps>`
  background-color: ${Styles.colors.neutral100};
  padding: 12px 20px 12px 10px;
  font-size: 14px;
  cursor: pointer;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 1;
  ${({ isFixed }) =>
    isFixed &&
    css`
      left: 0;
      z-index: 2;
    `}
`;

interface ITooltipWrapperProps {
  right?: number;
}

const TooltipWrapper = styled.div<ITooltipWrapperProps>`
  position: absolute;
  top: 40px;
  right: 0;
  ${({ right }) =>
    right &&
    css`
      right: ${right}px;
    `}
`;

const pulseAnimation = keyframes`
  0%,100% { opacity: 1; }
  50% { opacity: 0.3; }
`;

const LoadingCell = styled.div`
  width: 100px;
  height: 16px;
  background-color: ${Styles.colors.neutral100};
  border-radius: ${Styles.borderRadius.s};
  animation-name: ${pulseAnimation};
  animation-iteration-count: infinite;
  animation-duration: 2s;
`;

const LoadingAction = styled.div`
  width: 10px;
  height: 30px;
  background-color: ${Styles.colors.neutral100};
  border-radius: ${Styles.borderRadius.s};
  animation-name: ${pulseAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: auto;
`;

interface IDimensions {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  x?: number;
  y?: number;
  right?: number;
  bottom?: number;
}

interface IActivePopover {
  id: string;
  actions: any[];
  dimensions?: IDimensions;
}

export interface ITableHeader {
  label: string;
  id: string;
  width?: string;
  dataTestId?: string;
  hideDisabled?: boolean;
  sortASC?: Function;
  sortDES?: Function;
  clearSort?: Function;
}

export interface ITableData {
  id: string;
  value: any;
}

export interface ITableRow {
  id: string;
  checkboxDisabled?: boolean;
  isDefaultChecked?: boolean;
  actions?: any[]; // TODO: change actions type
  columns: ITableData[];
}

export interface TableSelection {
  id: string;
  isSelected: boolean;
}

interface ITableProps {
  columns: ITableHeader[];
  data: ITableRow[];
  width?: string;
  hideColumns?: boolean;
  dataTestId?: string;
  maxHeight?: string;
  defaultHiddenColumns?: string[];
  selectable?: boolean;
  onSelect?: (items: TableSelection[]) => void;
  fixedColumns?: number;
  actionPopoverWidth?: string;
  rightFixed?: boolean;
  loading?: boolean;
  emptyTemplate?: ReactNode;
  hideShadow?: boolean;
  onVisibleColumnsChange?: (columnIds: string[]) => void;
}
export const Table: FunctionComponent<ITableProps> = ({
  columns,
  width,
  data,
  hideColumns = false,
  dataTestId = 'table',
  maxHeight,
  defaultHiddenColumns = [],
  selectable = false,
  onSelect,
  fixedColumns = 0,
  actionPopoverWidth,
  rightFixed,
  loading = false,
  emptyTemplate,
  hideShadow = false,
  onVisibleColumnsChange,
}) => {
  const [activeSort, setActiveSort] = useState('');
  const [hiddenColumns, setHiddenColumns] = useState(defaultHiddenColumns);
  const [isHideColumnsPopoverActive, setIsHideColumnsPopoverActive] = useState(
    false
  );
  const [
    activeActionPopover,
    setActiveActionPopover,
  ] = useState<IActivePopover>({ id: '', actions: [] });
  const [selectedItems, setSelectedItems] = useState([]);
  const [columnWidths, setColumnWidths] = useState([]);
  const [resizeToggle, setResizeToggle] = useState(false);
  const { isHovered, handleHover, setHover } = useHover();
  const tableRef = useRef<HTMLElement>();
  const visibleTableRef = useRef<HTMLElement>();

  const handleActiveSort = (id: string) => {
    setActiveSort(id);
  };

  const handleResize = () => {
    setResizeToggle((resizeToggle) => !resizeToggle);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!activeActionPopover.id || !isHideColumnsPopoverActive) setHover(false);
  }, [activeActionPopover.id, isHideColumnsPopoverActive]);

  useEffect(() => {
    if (!selectable) {
      setSelectedItems([]);
      return;
    }

    const preselectedData = data
      .filter((row) => row.isDefaultChecked && !row.checkboxDisabled)
      .map(({ id }) => id);

    if (preselectedData === selectedItems) return;

    setSelectedItems(preselectedData);

    // we disable action menu when in select mode
    if (preselectedData.length) closeActionPopover();
  }, [data, selectable]);

  const handleColumnWidth = (column: { id: string; width: number }) => {
    // check if id exists in column width - this prevents duplicate ids in array
    if (columnWidths.some(({ id }) => id === column.id)) {
      // id is in column widths

      // get the index of the column with matching index, so we can update this
      const indexOfColumn = columnWidths.findIndex(
        ({ id }) => id === column.id
      );

      if (columnWidths[indexOfColumn].width !== column.width) {
        //  if the width is different update the width
        let updatedColumnWidths = [...columnWidths];
        updatedColumnWidths[indexOfColumn].width = column.width;
        return setColumnWidths(updatedColumnWidths);
      }
      return;
    }
    // add index to the column so we can sort it in the table header
    const index = columns.findIndex((col) => col.id === column.id);
    setColumnWidths((columnWidths) => [...columnWidths, { ...column, index }]);
  };

  const handleSelectAll = (isSelected: boolean): void => {
    closeActionPopover();

    const IDs = data
      .filter(({ checkboxDisabled }) => !checkboxDisabled)
      .map(({ id }) => id);

    const selectItems: TableSelection[] = IDs.map((id) => {
      return {
        id: id,
        isSelected: isSelected,
      };
    });
    onSelect(selectItems);
  };

  const handleSelectRow = (id: string) => (isSelected: boolean): void => {
    onSelect([
      {
        id: id,
        isSelected: isSelected,
      },
    ]);
  };

  const handleActionRow = (id: string, actions) => ({
    currentTarget,
  }: MouseEvent) => {
    if (selectedItems.length) {
      return;
    }

    if (activeActionPopover.id === id) {
      return closeActionPopover();
    }

    const dimensions = (currentTarget as HTMLElement).getBoundingClientRect();
    setActiveActionPopover({ id, actions, dimensions });
  };

  const handleAction = (buttonAction: () => void) => {
    closeActionPopover();
    buttonAction();
  };

  const renderOverlay = () => {
    if (activeActionPopover.id || isHideColumnsPopoverActive) {
      return <Overlay data-testid="popoverOverlay" onClick={handlePopover()} />;
    }
  };

  const renderSelectAll = () => {
    const isChecked =
      data
        .filter(({ checkboxDisabled }) => !checkboxDisabled)
        .map(({ id }) => id).length === selectedItems.length &&
      selectedItems.length !== 0;

    return (
      selectable && (
        <SelectAll isFixed={fixedColumns > 0}>
          <Checkbox
            dataTestId="selectAllCheckbox"
            onChange={handleSelectAll}
            isDefaultChecked={isChecked}
            disabled={loading}
            label="Select all"
            hideLabel
          />
        </SelectAll>
      )
    );
  };

  const renderHeader = () => {
    // only render headers which are visible
    const visibleColumns = columns.filter(
      ({ id }) => !hiddenColumns.includes(id)
    );
    return visibleColumns.map(
      (
        { label, id, width, dataTestId, sortASC, sortDES, clearSort },
        index
      ) => {
        return (
          <TableHeader
            dataTestId={dataTestId}
            label={label}
            id={id}
            key={id}
            width={width}
            handleActiveSort={handleActiveSort}
            activeSort={id === activeSort}
            isSelectable={selectable}
            sortASC={sortASC}
            sortDES={sortDES}
            clearSort={clearSort}
            isFixed={fixedColumns > index}
            handleColumnWidth={handleColumnWidth}
            columnWidths={columnWidths}
            fixedColumns={fixedColumns}
            loading={loading}
            hasData={!!data.length}
          />
        );
      }
    );
  };

  const renderHideColumnsPopover = () => {
    if (isHideColumnsPopoverActive) {
      const columnList = columns.map(({ label, hideDisabled = false, id }) => ({
        id,
        label,
        hideDisabled,
      }));
      return (
        <TablePopover right={getTableRightPosition()}>
          <HideColumnsTablePopover
            columns={columnList}
            onApply={handleHiddenColumns}
            onReset={clearHiddenColumns}
            hiddenColumns={hiddenColumns}
          />
        </TablePopover>
      );
    }
  };

  const renderHideColumnIcon = () => {
    const hasActions = data.some(({ actions }) => actions && !!actions.length);

    if (hideColumns) {
      return (
        <HideColumn
          isFixed={rightFixed}
          overlayActive={isHideColumnsPopoverActive}
          onMouseEnter={handleHover(true)}
          onMouseLeave={handleHover(false)}
        >
          <Icon
            dataTestId="hideColumnsIcon"
            ariaLabel="hide columns"
            name="faColumns"
            padding="4px"
            onClick={handlePopover('hideColumns')}
          />
        </HideColumn>
      );
    }

    if (!hideColumns && hasActions) {
      return <HideColumn isFixed={rightFixed} />;
    }
  };

  const checkSelected = (id: string) => {
    return selectedItems.includes(id);
  };

  const renderRows = () => {
    const rows = data.map(({ columns, id, actions, checkboxDisabled }) => {
      const rowId = id;
      const isChecked = checkSelected(id);
      const visibleColumns = columns.filter(({ id }) => {
        return !hiddenColumns.includes(id);
      });

      const renderActionCell = () => {
        const hasActions = actions && !!actions.length;

        if (loading && (hasActions || hideColumns)) {
          return (
            <ActionCell
              isFixed={rightFixed}
              data-testid="loadingActionCell"
              isSelected={isChecked}
            >
              <LoadingAction />
            </ActionCell>
          );
        }

        const isPopoverActive = activeActionPopover.id === id;

        if (hasActions || hideColumns) {
          return (
            <ActionCell
              data-testid={`action-cell-${id}`}
              isFixed={rightFixed}
              overlayActive={isPopoverActive}
              isSelected={isChecked}
              disabled={selectedItems.length}
            >
              {hasActions && (
                <Icon
                  name="faEllipsisV"
                  ariaLabel="Actions"
                  dataTestId={`action-cell-${id}-icon`}
                  onClick={handleActionRow(id, actions)}
                />
              )}
            </ActionCell>
          );
        }
      };

      return (
        <Row key={id}>
          {selectable ? (
            <Cell
              isFixed={fixedColumns > 0}
              leftPosition={0}
              isSelected={checkSelected(rowId)}
            >
              <Checkbox
                dataTestId={`rowCheckbox-${id}`}
                onChange={handleSelectRow(id)}
                isDefaultChecked={isChecked}
                disabled={loading || checkboxDisabled}
                label="Select row"
                hideLabel
              />
            </Cell>
          ) : null}
          {visibleColumns.map(({ value, id }, index) => {
            const isFixed = fixedColumns > index;
            const rightBorder = index + 1 === fixedColumns;
            const leftPosition = columnWidths.reduce((acc, val, arrIndex) => {
              return arrIndex < index ? acc + val.width : acc;
            }, 0);
            return (
              <Cell
                isSelected={checkSelected(rowId)}
                isSelectable={selectable}
                rightBorder={rightBorder}
                leftPosition={leftPosition}
                isFixed={isFixed}
                key={id}
              >
                {loading ? <LoadingCell data-testid="loadingCell" /> : value}
              </Cell>
            );
          })}
          {renderActionCell()}
        </Row>
      );
    });

    // add in loading rows if no rows exist and loading is true
    if (!rows.length && loading) {
      let i = 0;
      let loadingRows = [];

      const visibleColumns = columns.filter(({ id }) => {
        return !hiddenColumns.includes(id);
      });

      while (i <= 4) {
        loadingRows.push(
          <Row data-testid="loadingRow" key={`loadingRow-${i}`}>
            {selectable ? (
              <Cell>
                <Checkbox
                  label="select row"
                  hideLabel
                  disabled
                  onChange={handleSelectRow(i.toString())}
                />
              </Cell>
            ) : null}
            {visibleColumns.map(({ id }, index) => {
              const isFixed = fixedColumns > index;
              const rightBorder = index + 1 === fixedColumns;
              const leftPosition = columnWidths.reduce((acc, val, arrIndex) => {
                return arrIndex < index ? acc + val.width : acc;
              }, 0);

              return (
                <Cell
                  isSelectable={selectable}
                  rightBorder={rightBorder}
                  leftPosition={leftPosition}
                  isFixed={isFixed}
                  key={id}
                >
                  <LoadingCell />
                </Cell>
              );
            })}
            {hideColumns ? <Cell /> : null}
          </Row>
        );
        i++;
      }
      return loadingRows;
    }

    // empty state for the table
    if (!rows.length && !loading) {
      const numberOfColumns = columns.filter(
        ({ id }) => !hiddenColumns.includes(id)
      ).length;
      const colSpan = selectable ? numberOfColumns + 1 : numberOfColumns;
      return (
        <tr data-testid="emptyTable">
          <td colSpan={colSpan}>
            {emptyTemplate ? emptyTemplate : <EmptyTableTemplate />}
          </td>
        </tr>
      );
    }

    return rows;
  };

  const handlePopover = (popover?: string) => () => {
    if (popover === 'hideColumns') {
      return setIsHideColumnsPopoverActive((isActive) => !isActive);
    }

    setIsHideColumnsPopoverActive(false);
    return closeActionPopover();
  };

  const closeActionPopover = () => {
    setActiveActionPopover({ id: '', actions: [] });
  };

  const updateHiddenColumns = (newHiddenColumnsSelected: string[]) => {
    setHiddenColumns(newHiddenColumnsSelected);

    const visibleColumns = columns
      .filter(({ id }) => !newHiddenColumnsSelected.includes(id))
      .map(({ id }) => id);

    onVisibleColumnsChange && onVisibleColumnsChange(visibleColumns);
  };

  const handleHiddenColumns = (newHiddenColumnsSelected: string[]) => {
    // this filters the column width and returns a new array wwhere any id cannot match the ids returned from hidden columns
    const updatedColumnWidths = columnWidths.filter(({ id }) => {
      return !newHiddenColumnsSelected.some((column) => id === column);
    });
    setColumnWidths(updatedColumnWidths);
    setIsHideColumnsPopoverActive(false);

    updateHiddenColumns(newHiddenColumnsSelected);
  };

  const clearHiddenColumns = () => {
    setIsHideColumnsPopoverActive(false);

    updateHiddenColumns(defaultHiddenColumns);
  };

  const getTableRightPosition = () => {
    if (visibleTableRef.current) {
      const {
        right: tableRight,
      } = tableRef.current.getBoundingClientRect() as IDimensions;
      const {
        right: visibleTableRight,
      } = visibleTableRef.current.getBoundingClientRect() as IDimensions;
      return tableRight - visibleTableRight;
    }
  };

  const renderActionsPopover = () => {
    if (activeActionPopover.id) {
      const {
        y: tableY,
      } = tableRef.current.getBoundingClientRect() as IDimensions;
      const actionCellX = getTableRightPosition();
      const actionCellY = activeActionPopover.dimensions.y - tableY + 13;
      return (
        <TablePopover
          width={actionPopoverWidth}
          top={actionCellY}
          right={actionCellX}
        >
          <ActionLinksTablePopover
            onButtonClick={handleAction}
            buttons={activeActionPopover.actions}
          />
        </TablePopover>
      );
    }
  };

  return (
    <Container ref={tableRef}>
      <TooltipWrapper right={getTableRightPosition() + 88}>
        <Tooltip text="Hide columns" visible={isHovered} width="106px" />
      </TooltipWrapper>
      <ScrollWrapper
        width={width}
        hideShadow={hideShadow}
        ref={visibleTableRef}
      >
        <Wrapper maxHeight={maxHeight}>
          <TableWrapper data-testid={dataTestId}>
            <thead>
              <HeaderRow>
                {renderSelectAll()}
                {renderHeader()}
                {renderHideColumnIcon()}
              </HeaderRow>
            </thead>
            <tbody>{renderRows()}</tbody>
          </TableWrapper>
        </Wrapper>
      </ScrollWrapper>
      {renderOverlay()}
      {renderHideColumnsPopover()}
      {renderActionsPopover()}
    </Container>
  );
};

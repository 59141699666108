import React, { FunctionComponent } from 'react';
import Styled from 'styled-components';
import { Styles } from '../..';

const Wrapper = Styled.div`
    display: grid;
    grid-auto-flow: columns;
`;

const StyledButton = Styled.button`
  height: 36px;
  border-radius: ${Styles.borderRadius.s};
  outline: none;
  line-height: 20px;
  border: none;
  transition: ${Styles.transitions.button};
  font-size: ${Styles.fontSizes.s3};
  font-family: ${Styles.fonts.primary};
  font-weight: ${Styles.fontWeights.medium};
  color: ${Styles.colors.blue500};
  background-color: ${Styles.colors.white};
  cursor: pointer;
  white-space: nowrap;

  &:hover, 
  &:focus {
    background-color: ${Styles.colors.blue100};
  }
`;

interface ITableActionPopoverLinks {
  buttons: { label: string; action: Function; dataTestId?: string }[];
  onButtonClick: Function;
}

export const ActionLinksTablePopover: FunctionComponent<ITableActionPopoverLinks> = ({
  buttons,
  onButtonClick,
}) => {
  const handleClick = (action) => () => {
    onButtonClick(action);
  };

  const renderButtons = () => {
    return buttons.map(({ label, action, dataTestId }, index) => {
      return (
        <StyledButton
          data-testid={dataTestId}
          key={`${label}-${index}`}
          onClick={handleClick(action)}
        >
          {label}
        </StyledButton>
      );
    });
  };

  return (
    <Wrapper data-testid="actionLinksTablePopover">{renderButtons()}</Wrapper>
  );
};

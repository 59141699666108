import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHover } from '../util/Hooks';
import { Styles } from '../util/Styles';
import Styled, { css } from 'styled-components';
import { Icon } from './Icon';

export interface IInlineEditProps {
  value: string;
  onSave: (value: string) => void;
  width?: string;
  dataTestId?: string;
}

interface IIsHoveredProp {
  isHovered: boolean;
}

const Wrapper = Styled.div`
font-family: ${Styles.fonts.primary};
font-size: 32.44px;
white-space: nowrap;
min-height: 52px;
`;

const ActiveWrapper = Styled.div`
border: 0;
padding: 0 0 4px 0;
`;

const InactiveActiveWrapper = Styled.div`
cursor: pointer;
display: inline-flex;
padding: 0 0 2px 0;
&:focus{outline: none;}
`;

const InputWrapper = Styled.input`
font-family: ${Styles.fonts.primary};
font-size: 32.44px;
color: ${Styles.colors.text.darkPrimary};
outline: 0;
border: 0;
border-bottom: 2px solid rgba(0,0,0,0.20);   
border-radius: 0px;
padding: 0;
${({ width }) =>
  width &&
  css`
    width: ${width};
  `}
`;

const ValueWrapper = Styled.div<IIsHoveredProp>`
color: ${Styles.colors.text.darkPrimary};
border-bottom-color: transparent;
text-overflow: ellipsis;
overflow: hidden;
padding: 0;
${({ width }) =>
  width &&
  css`
    width: ${width};
  `}

${({ isHovered }) =>
  isHovered &&
  css`
    border: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    padding: 0px;
    transition: border-bottom-color 0.17s ease-in;
  `}
`;

const IconWrapper = Styled.div<IIsHoveredProp>`
font-size: 22px;
margin-top: 6px;
margin-left: 12px;
visibility: hidden;
opacity: 0;

${({ isHovered }) =>
  isHovered &&
  css`
    visibility: visible;
    opacity: 0.2;
    transition: visibility 0s linear 0s, opacity 300ms ease-in;
  `}
`;

export const InlineEdit: FunctionComponent<IInlineEditProps> = ({
  value,
  width = '25vw',
  dataTestId = 'inlineEdit',
  onSave,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const { isHovered, handleHover, setHover } = useHover();

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const cancelInput = () => {
    setNewValue(value);
    setIsEditable(false);
    setHover(false);
  };

  const confirmInput = () => {
    setIsEditable(false);
    const trimmedValue = newValue.trim();

    if (trimmedValue.length > 0 && trimmedValue !== value) {
      onSave(trimmedValue);
      setNewValue(trimmedValue);
      setHover(false);
    } else {
      cancelInput();
    }
  };

  const makeEditable = () => {
    setNewValue(newValue);
    setIsEditable(true);
  };

  const handleKeyDown = (handleKey: () => void, keys: string[]) => (
    event: KeyboardEvent
  ) => {
    if (keys.find((key) => key === event.key)) {
      handleKey();
    }
  };

  const handleMakeEditable = () => {
    makeEditable();
  };

  const handleConfirmInput = () => {
    confirmInput();
  };

  const handleOnChange = (e) => {
    setNewValue(e.target.value);
  };

  return (
    <Wrapper data-testid={dataTestId} onClick={handleMakeEditable}>
      {isEditable ? (
        <ActiveWrapper>
          <InputWrapper
            data-testid={dataTestId + 'Input'}
            width={width}
            onBlur={handleConfirmInput}
            value={newValue}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown(confirmInput, ['Enter', 'Escape'])}
          ></InputWrapper>
        </ActiveWrapper>
      ) : (
        <InactiveActiveWrapper
          onMouseEnter={handleHover(true)}
          onMouseLeave={handleHover(false)}
          onFocus={handleHover(true)}
          onBlur={handleHover(false)}
          tabIndex={0}
          onKeyDown={handleKeyDown(makeEditable, ['Enter'])}
        >
          <ValueWrapper
            width={width}
            isHovered={isHovered}
            data-testid={dataTestId + 'Value'}
          >
            {newValue}
          </ValueWrapper>
          <IconWrapper isHovered={isHovered}>
            <Icon ariaLabel="Edit" name={'faEdit'} />
          </IconWrapper>
        </InactiveActiveWrapper>
      )}
    </Wrapper>
  );
};

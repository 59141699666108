import React, {
  FunctionComponent,
  Fragment,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import styled, { css } from 'styled-components';
import { Styles } from '../util/Styles';
import { Icon } from './Icon';

interface IBackgroundProps {
  active: boolean;
}

interface IWrapperProps {
  minHeight?: number;
  minWidth?: number;
  width?: string;
  maxWidth?: string;
}

const Background = styled.div<IBackgroundProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${Styles.colors.black};
  z-index: 998;
  display: none;
  opacity: 0;
  ${({ active }) =>
    active &&
    css`
      opacity: 0.5;
      display: block;
    `}
`;

const Container = styled.div<IBackgroundProps>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  align-items: center;
  height: 100%;
  justify-content: center;
  opacity: 0.5;
  display: none;
  pointer-events: none;

  ${({ active }) =>
    active &&
    css`
      display: grid;
      opacity: 1;
    `}
`;

const Wrapper = styled.div<IWrapperProps>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: ${Styles.colors.white};
  min-width: 480px;
  z-index: 1000;
  pointer-events: all;
  border-radius: ${Styles.borderRadius.s};
  box-sizing: border-box;
  padding: 16px;
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  min-height: ${({minHeight}) => minHeight || 0};
  min-width: ${({ minWidth }) => minWidth || 0}
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: start;
  column-gap: 16px;
`;

const Title = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-weight: ${Styles.fontWeights.medium};
  line-height: 40px;
  font-size: ${Styles.fontSizes.m5};
  font-family: ${Styles.fonts.primary};
  margin: 0;
  word-break: break-all;
`;

const IconWrapper = styled.div`
  padding-top: 8px;
  color: ${Styles.colors.neutral700};
  cursor: pointer;
  display: grid;
  justify-content: end;
  font-size: ${Styles.fontSizes.m4};
  &:hover {
    color: ${Styles.colors.neutral900};
  }
`;

const Body = styled.div`
  padding: 16px 0;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const FooterContentContainer = styled.div`
  margin-right: 8px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  position: relative;
  z-index: 0;
`;

interface IModalProps {
  title: string;
  active?: boolean;
  buttons?: ReactNode[];
  footerContent?: ReactNode | string;
  minHeight?: number;
  width?: string;
  maxWidth?: string;
  closeOnBlur?: boolean;
  minWidth?: number;
  closeCallback?: () => void;
  dataTestId?: string;
}

export const Modal: FunctionComponent<IModalProps> = ({
  title,
  active = false,
  children,
  buttons,
  footerContent,
  minHeight,
  closeOnBlur,
  minWidth,
  closeCallback,
  dataTestId = 'modal',
  width,
  maxWidth
}) => {
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const handleClose = () => {
    setIsActive(false);
    closeCallback && closeCallback();
  };

  const handleBlur = () => {
    closeOnBlur && handleClose();
  };

  return (
    <Fragment>
      <Container active={isActive}>
        <Wrapper
          data-testid={dataTestId}
          minHeight={minHeight}
          minWidth={minWidth}
          width={width}
          maxWidth={maxWidth}
        >
          <Header>
            <Title>{title}</Title>
            <IconWrapper data-testid="close-button" onClick={handleClose}>
              <Icon
                dataTestId="close-icon"
                ariaLabel="Close Modal"
                name="faTimes"
                variant="light"
              />
            </IconWrapper>
          </Header>
          <Body>{children}</Body>
          <Footer>
            <FooterContentContainer>{footerContent}</FooterContentContainer>
            <ButtonContainer>{buttons}</ButtonContainer>
          </Footer>
        </Wrapper>
      </Container>
      <Background
        active={isActive}
        onClick={handleBlur}
        data-testid="modal-background"
      />
    </Fragment>
  );
};

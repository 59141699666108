import React, { FunctionComponent } from 'react';
import Styled, { css } from 'styled-components';
import { Styles } from '../../util/Styles';
import { Checkbox } from '../Checkbox';

export interface DropdownOptionsProps {
  onValueChange: Function;
  items: DropdownItem[];
  dataTestId?: string;
  width?: string;
  height?: string;
  minWidth?: string;
  multiSelect?: boolean;
  sidePadding?: string;
  id?: string;
}

export interface DropdownItem {
  id: string;
  value: string;
  disabled?: boolean;
  isSelected?: boolean;
  isDanger?: boolean;
}

interface ItemsWrapperProps {
  minWidth?: string;
  width?: string;
  height?: string;
}

const ItemsWrapper = Styled.div<ItemsWrapperProps>`
  box-sizing: border-box;
  display: grid;
  width: 100%;
  overflow-x: hidden;
  transform-origin: top;
  background-color: ${Styles.colors.white};
  border-radius: 4px;
  box-shadow: 0px 1px 10px ${Styles.colors.neutral300};
  transition: all 0.5s;
  pointer-events: all;
  transition: all 1s ease-in;
  padding: 8px;

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ height }) =>
    height &&
    css`
      max-height: ${height};
    `}
`;

interface ItemWrapperProps {
  disabled: boolean;
  sidePadding: string;
  isDanger?: boolean;
}

const ItemWrapper = Styled.div<ItemWrapperProps>`
  font-family: ${Styles.fonts.primary};
  justify-self: start;
  text-align: start;
  display: grid;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ isDanger }) =>
    isDanger ? Styles.colors.red700 : Styles.colors.neutral900};

  &:hover,
  &:focus,
  &:active {
    color: ${({ isDanger }) =>
      isDanger ? Styles.colors.red500 : Styles.colors.blue700};
    background-color: ${({ isDanger }) =>
      isDanger ? Styles.colors.red100 : Styles.colors.blue100};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      background: none;
      opacity: 0.65;
    `}
`;

interface ItemTextProps {
  sidePadding?: string;
}

const ItemText = Styled.div<ItemTextProps>`
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${Styles.fontSizes.m1};
  padding: 8px ${({ sidePadding }) => sidePadding || '8px'};
`;

export const DropdownOptions: FunctionComponent<DropdownOptionsProps> = ({
  items,
  multiSelect = false,
  dataTestId = 'dropdown',
  minWidth = '90px',
  width = null,
  height = '220px',
  onValueChange,
  sidePadding = '8px',
}) => {
  const renderedItems = items.map(
    ({
      disabled,
      value,
      id,
      isSelected = false,
      isDanger = false,
    }: DropdownItem) => {
      let itemTestId = `${dataTestId}-item-${id}`;
      const handleSelect = () => {
        if (!disabled) {
          return onValueChange({ id, value });
        }
      };
      const handleItemClick = (event: MouseEvent) => {
        //event.stopPropagation();
        handleSelect();
      };

      const renderMultiSelectItem = () => {
        return (
          multiSelect && (
            <ItemText sidePadding={sidePadding}>
              <Checkbox
                isDefaultChecked={isSelected}
                label={value}
                disabled={disabled}
                maxWidth={width}
                onChange={handleSelect}
              />
            </ItemText>
          )
        );
      };

      const renderSingleSelectItem = () => {
        return (
          !multiSelect && (
            <ItemText sidePadding={sidePadding} onClick={handleItemClick}>
              {value}
            </ItemText>
          )
        );
      };

      return (
        <ItemWrapper
          disabled={disabled}
          data-testid={itemTestId}
          key={id}
          isDanger={isDanger}
        >
          {renderMultiSelectItem()}
          {renderSingleSelectItem()}
        </ItemWrapper>
      );
    }
  );

  return (
    <ItemsWrapper
      minWidth={minWidth}
      width={width}
      height={height}
      data-testid={dataTestId}
    >
      {renderedItems}
    </ItemsWrapper>
  );
};

import React, { FC, useState, Fragment, useRef, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { Styles } from '../util/Styles';
import { Icon } from './Icon';
import { DropdownOptions, DropdownItem } from './dropdown/DropdownOptions';

const blueBorder = `${Styles.colors.blue500}${Styles.colors.opacity50}`;

const Wrapper = styled.button`
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  min-width: ${({ minWidth }) => minWidth || '0'};
  border: 2px solid transparent;
  border-radius: ${Styles.borderRadius.s};
  padding: 10px 16px;
  font-family: ${Styles.fonts.primary};
  font-size: ${Styles.fontSizes.s3};
  color: ${Styles.colors.white};
  cursor: pointer;
  outline: 0;
  transition: 0.1s;
  word-break: break-word;
  z-index: 1;
  position: relative;
  &:active {
    transform: scale(0.96);
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: 0 0 0 2px ${Styles.colors.white}, 0 0 0 4px ${blueBorder};
  }

  ${({ type }) => {
    if (type === 'primary' || type === 'dropdown') {
      return css`
        background-color: ${({ isDanger }) =>
          isDanger ? Styles.colors.red500 : Styles.colors.blue500};

        &:focus,
        &:hover {
          background-color: ${({ isDanger }) =>
            isDanger ? Styles.colors.red700 : Styles.colors.blue700};
        }
      `;
    } else if (type === 'dropdown-action') {
      return css`
        display: grid;
        background-color: ${Styles.colors.blue500};

        ${({ loading }) =>
          loading &&
          css`
            padding: 10px 32px;
          `}

        ${({ loading }) =>
          !loading &&
          css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          `}

        &:focus,
        &:hover {
          background-color: ${Styles.colors.blue700};
        }
        &:active {
          transform: scale(1);
        }
      `;
    } else if (type === 'secondary') {
      return css`
        background-color: ${Styles.colors.neutral100};
        border: 2px solid ${Styles.colors.neutral300}${Styles.colors.opacity40};
        color: ${({ isDanger }) =>
          isDanger ? Styles.colors.red700 : Styles.colors.neutral700};

        &:focus,
        &:hover,
        &:active {
          color: ${({ isDanger }) =>
            isDanger ? Styles.colors.red700 : Styles.colors.neutral900};
        }
      `;
    } else if (type === 'text') {
      return css`
        padding: 0;
        background-color: transparent;
        text-decoration: underline;
        color: ${({ isDanger }) =>
          isDanger ? Styles.colors.red500 : Styles.colors.neutral700};

        &:focus,
        &:hover,
        &:active {
          color: ${({ isDanger }) =>
            isDanger ? Styles.colors.red700 : Styles.colors.blue500};
        }
        &:disabled {
          color: ${Styles.colors.neutral500};
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
        }
      `;
    }
  }}
`;

const IconWrapper = styled.span`
  padding-left: 16px;
`;

interface IconLabelProps {
  withLabel?: boolean;
}

const IconLabel = styled.span<IconLabelProps>`
  padding-right: ${({ withLabel }) => (withLabel ? '8px' : '')};
`;

interface OptionsWrapperProps {
  width?: string;
  type?: string;
}
const OptionsWrapper = styled.div<OptionsWrapperProps>`
  z-index: 1;
  position: absolute;
  top: 45px;
  right: -2px;
  width: ${({ width }) => width || 'auto'};
  display: grid;
  justify-content: end;
  color: ${Styles.colors.neutral900};
  ${({ type }) =>
    type === 'dropdown-action' &&
    css`
      right: -34px;
    `}
`;

interface OverlayProps {
  active: boolean;
}

const Overlay = styled.div<OverlayProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  display: ${({ active }) => (active ? 'block' : 'none')};
`;
interface ActionButtonProps {
  width?: string;
  disabled?: boolean;
  loading?: boolean;
}

const ActionButton = styled.button<ActionButtonProps>`
  border: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${Styles.colors.blue700}${Styles.colors.opacity50};
  color: ${Styles.colors.white};
  background-color: ${Styles.colors.blue500};
  background-color: ${({ disabled }) =>
    disabled ? Styles.colors.neutral300 : Styles.colors.blue500};
  width: 30px;
  padding: 12px 16px;
  border-top-right-radius: ${Styles.borderRadius.s};
  border-bottom-right-radius: ${Styles.borderRadius.s};
  z-index: 1;
  cursor: pointer;
  &:focus {
    background-color: ${Styles.colors.blue700};
    box-shadow: 0 0 0 2px ${Styles.colors.white}, 0 0 0 4px ${blueBorder};
  }
  &:hover {
    background-color: ${Styles.colors.blue700};
  }
  &:disabled {
    cursor: not-allowed;
    &:hover {
      background-color: ${Styles.colors.neutral300};
    }
  }
`;

const ActionButtonWrapper = styled.div<ActionButtonProps>`
  width: auto;
  display: grid;
  grid-template-columns: ${({ loading }) =>
    loading ? 'max-content' : 'max-content 30px'};

  ${({ width }) =>
    width !== 'auto' &&
    css`
      width: ${width};
      grid-template-columns: ${({ loading }) =>
        loading ? width : `calc(${width} - 33px) 30px`};
    `}
`;

const LoadingText = styled.span`
  margin-right: 8px;
`;

const LoadingContainer = styled.div`
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingInnerContainer = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
`;

interface LabelSpaceWrapperProps {
  type: string;
  visibile: boolean;
}

const LabelSpaceWrapper = styled.span<LabelSpaceWrapperProps>`
  width: auto;
  visibility: ${({ visibile }) => (visibile ? 'visible' : 'hidden')};

  ${({ type, visibile }) =>
    type === 'dropdown' &&
    !visibile &&
    css`
      margin-right: 25px;
    `}
`;

interface Props {
  dataTestId?: string;
  label: string;
  hideLabel?: boolean;
  onClick: () => void;
  onDropdownClick?: ({ id, value }) => void;
  type?: 'primary' | 'secondary' | 'text' | 'dropdown' | 'dropdown-action';
  isDanger?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  disabled?: boolean;
  dropdownWidth?: string;
  dropdownMinWidth?: string;
  dropdownOptions?: DropdownItem[];
  loading?: boolean;
  loadingText?: string;
  name?: string;
  icon?: string;
}

export const Button: FC<Props> = ({
  dataTestId = 'button',
  label,
  hideLabel = false,
  type = 'primary',
  isDanger = false,
  disabled = false,
  width,
  minWidth,
  maxWidth,
  dropdownWidth,
  dropdownMinWidth = '180px',
  dropdownOptions,
  loading,
  onClick,
  onDropdownClick,
  loadingText,
  name = label,
  icon,
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);

  const renderDropdownIcon = () => {
    const isDropdown = type === 'dropdown' && !loading;

    if (isDropdown) {
      return (
        <IconWrapper>
          <Icon name="faCaretDown" />
        </IconWrapper>
      );
    }

    return null;
  };

  const closeDropdown = () => {
    setDropdownActive(false);
  };

  const renderDropdown = () => {
    const shouldDisplayDropdown =
      (type === 'dropdown' || type === 'dropdown-action') &&
      dropdownActive &&
      dropdownOptions &&
      dropdownOptions.length &&
      onDropdownClick &&
      !loading;

    if (shouldDisplayDropdown) {
      return (
        <OptionsWrapper
          onClick={closeDropdown}
          type={type}
          data-testid="dropdown"
          width={width}
          aria-labelledby={label}
        >
          <DropdownOptions
            dataTestId={`${dataTestId}-options`}
            width={dropdownWidth}
            minWidth={dropdownMinWidth}
            items={dropdownOptions}
            onValueChange={onDropdownClick}
          />
        </OptionsWrapper>
      );
    }

    return null;
  };

  const renderIcon = () => {
    if (icon) {
      return (
        <IconLabel withLabel={!hideLabel}>
          <Icon name={icon} />
        </IconLabel>
      );
    }
    return null;
  };

  const renderLabel = () => (
    <Fragment>
      {loading && (
        <LoadingContainer>
          <LoadingInnerContainer>
            {loadingText && (
              <LoadingText data-testid="loading-text">
                {loadingText}
              </LoadingText>
            )}

            <Icon name="faSpinner" spin />
          </LoadingInnerContainer>
        </LoadingContainer>
      )}

      <LabelSpaceWrapper type={type} visibile={!loading}>
        {renderIcon()}
        {!hideLabel && label}
      </LabelSpaceWrapper>
    </Fragment>
  );

  const handleClick = () => {
    if (type === 'dropdown') {
      return setDropdownActive((isActive) => !isActive);
    }

    if (!loading) {
      onClick();
    }
  };

  const handleActionButtonClick = () => {
    setDropdownActive((isActive) => !isActive);
  };

  const renderButton = () => {
    if (type !== 'dropdown-action') {
      return (
        <Fragment>
          <Overlay
            data-testid="overlay"
            active={dropdownActive}
            onClick={closeDropdown}
          />
          <Wrapper
            data-testid={dataTestId}
            type={type}
            isDanger={isDanger}
            disabled={disabled || loading}
            width={width}
            minWidth={minWidth}
            maxWidth={maxWidth}
            onClick={handleClick}
            name={name}
            aria-label={label}
          >
            {renderLabel()}
            {renderDropdownIcon()}
            {renderDropdown()}
          </Wrapper>
        </Fragment>
      );
    }

    const renderActionButton = () => {
      if (!loading) {
        return (
          <ActionButton
            name="dropdownButton"
            onClick={handleActionButtonClick}
            data-testid="dropdown-button"
            disabled={disabled || loading}
            loading={loading}
            aria-label={`Open options for ${label}`}
          >
            <Icon name="faCaretDown" />
          </ActionButton>
        );
      }
      return null;
    };

    return (
      <ActionButtonWrapper width={width} loading={loading}>
        <Overlay
          data-testid="overlay"
          active={dropdownActive}
          onClick={closeDropdown}
        />
        <Wrapper
          data-testid={dataTestId}
          type={'dropdown-action'}
          isDanger={isDanger}
          disabled={disabled || loading}
          minWidth={minWidth}
          maxWidth={maxWidth}
          onClick={handleClick}
          loading={loading}
          name={name}
          aria-label={label}
        >
          {renderLabel()}
          {renderDropdown()}
        </Wrapper>
        {renderActionButton()}
      </ActionButtonWrapper>
    );
  };

  return renderButton();
};

import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Styles } from '../../util/Styles';
import { useHover } from '../../util/Hooks';
import { Icon } from './../Icon';

interface IWrapperProps {
  width?: string;
  isFixed?: boolean;
  leftPosition: number;
  rightBorder?: boolean;
  isLoading: boolean;
}

const Wrapper = styled.th<IWrapperProps>`
  position: relative;
  background-color: ${Styles.colors.neutral100};
  padding: 10px;
  box-sizing: border-box;
  text-transform: uppercase;
  color: #0000008a; //TODO: find this colour
  font-size: 11px;
  font-family: ${Styles.fonts.primary};
  width: auto;
  position: sticky;
  top: 0;
  z-index: 1;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ isFixed, isSelectable, leftPosition }) =>
    isFixed &&
    css`
      z-index: 2;
      left: ${isSelectable
        ? leftPosition + 49
        : leftPosition
        ? leftPosition
        : 0}px;
    `}
    ${({ rightBorder }) =>
    rightBorder &&
    css`
      &:after {
        height: calc(100% + 1px);
        content: '';
        width: 10px;
        background: transparent
          linear-gradient(90deg, #22272b 0%, #22272b00 100%) 0% 0% no-repeat
          padding-box;
        right: 0;
        position: absolute;
        top: 0;
        opacity: 0.15;
      }
    `}
  ${({ hasSort, isLoading }) =>
    hasSort &&
    !isLoading &&
    css`
      cursor: pointer;
      user-select: none;
      transition: 0.4s;
      &:hover {
        background: ${Styles.colors.neutral300};
      }
    `}
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface IIconWrapper {
  active: boolean;
  isLoading: boolean;
}

const SortIconWrapper = styled.div<IIconWrapper>`
  grid-column: 1;
  user-select: none;
  font-size: ${Styles.fontSizes.s3};
  margin-right: 10px;
  color: ${Styles.colors.black};
  ${({ isLoading }) =>
    !isLoading &&
    css`
      cursor: pointer;
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${Styles.colors.neutral900};
    `}
`;

interface ITableHeader {
  label: string;
  width?: string;
  handleActiveSort: Function;
  id: string;
  activeSort: boolean;
  dataTestId: string;
  isSelectable?: boolean;
  sortASC?: Function;
  sortDES?: Function;
  clearSort?: Function;
  fixedColumns?: number;
  isFixed: boolean;
  handleColumnWidth: Function;
  columnWidths: { id: string; width: number; index: number }[];
  loading: boolean;
  hasData: boolean;
}

export const TableHeader: FunctionComponent<ITableHeader> = ({
  label,
  width,
  handleActiveSort,
  id,
  activeSort,
  dataTestId,
  isSelectable = false,
  sortASC,
  sortDES,
  clearSort,
  isFixed,
  handleColumnWidth,
  columnWidths,
  fixedColumns,
  loading,
  hasData,
}) => {
  const { handleHover } = useHover();
  const [sortOrder, setSortOrder] = useState('');
  const ref = useRef(null);
  const hasSort = sortASC && sortDES && clearSort && hasData;

  useEffect(() => {
    if (ref && isFixed) {
      handleColumnWidth({ id, width: ref.current.offsetWidth });
    }
  });

  useEffect(() => {
    !activeSort && setSortOrder('');
  }, [activeSort]);

  const handleSort = () => {
    const canSort = !loading && hasSort;
    handleActiveSort(id);
    if (canSort) {
      switch (sortOrder) {
        case 'ascending':
          setSortOrder('descending');
          return sortDES();
        case 'descending':
          setSortOrder('');
          handleActiveSort('');
          return clearSort();
        default:
          setSortOrder('ascending');
          return sortASC();
      }
    }
  };

  const renderSort = () => {
    const iconName =
      sortOrder === ''
        ? 'faSort'
        : sortOrder === 'descending'
        ? 'faSortDown'
        : 'faSortUp';

    return (
      hasData &&
      sortASC &&
      sortDES &&
      clearSort && (
        <SortIconWrapper
          isLoading={loading}
          data-testid={`sort-${id}`}
          onClick={handleSort}
          isActive={activeSort}
        >
          <Icon ariaLabel="Sort" name={iconName} />
        </SortIconWrapper>
      )
    );
  };

  const renderHeader = () => {
    // sort the column widths so that the index is in order when we hide or show columns
    const sortedColumnWidths = columnWidths.sort((a, b) => {
      return a.index - b.index;
    });

    // get the index of the column so we can add up all widths up to the index of current column
    const headerIndex = sortedColumnWidths.findIndex((col) => col.id === id);

    // add in the widths until you reach the current index of the header
    const leftPosition = sortedColumnWidths.reduce((acc, val, arrIndex) => {
      return arrIndex < headerIndex ? acc + val.width : acc;
    }, 0);

    const rightBorder = fixedColumns ? headerIndex + 1 === fixedColumns : false;

    return (
      <Wrapper
        onMouseEnter={handleHover(true)}
        onMouseLeave={handleHover(false)}
        width={width}
        data-testid={dataTestId}
        isSelectable={isSelectable}
        isFixed={isFixed}
        ref={ref}
        leftPosition={leftPosition}
        rightBorder={rightBorder}
        hasSort={hasSort}
        isLoading={loading}
        onClick={handleSort}
      >
        <LabelWrapper>
          {renderSort()}
          {label}
        </LabelWrapper>
      </Wrapper>
    );
  };
  return renderHeader();
};

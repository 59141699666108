import React, { FunctionComponent, useState } from 'react';
import { useHover } from '../util/Hooks';
import { Styles } from '../util/Styles';
import Styled, { css } from 'styled-components';
import { Tooltip } from './Tooltip';
import { Icon } from './Icon';

export interface IRadioButtonProps {
  isDefaultChecked?: boolean;
  disabled?: boolean;
  onChange: Function;
  isSelected: boolean;
  dataTestId?: string;
  label: string;
  tooltip?: string;
  id: string;
}

interface IWrapperProps {
  disabled: boolean;
}

const Wrapper = Styled.div<IWrapperProps>`
  display: grid;
  grid-template-columns: 32px auto 1fr;
  position: relative;
  white-space: nowrap;
  font-family: ${Styles.fonts.primary};
`;

interface ILabelProps {
  disabled: boolean;
}

const Label = Styled.label<ILabelProps>`
  cursor: pointer;
  text-align: left;
  margin: 0;
  user-select: none;
  white-space: nowrap;
  font-weight: ${Styles.fontWeights.medium};
  font-size: ${Styles.fontSizes.s3};
  align-self: center;
  font-family: ${Styles.fonts.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ disabled }) =>
    disabled &&
    css`
      font-weight: ${Styles.fontWeights.extralight};
      cursor: not-allowed;
    `}
`;

interface IAnimatedCircleProps {
  isHovered: boolean;
  isSelected: boolean;
  disabled: boolean;
}

const AnimatedCircle = Styled.span<IAnimatedCircleProps>`
  padding: 2px;
  border: ${Styles.colors.neutral700} 2px solid;
  border-radius: 64px;
  align-self: center;
  margin: 8px;
  transition: 50ms all ease-in;
  cursor: pointer;

  &:after {
      display: block;
      padding: 4px;
      content: '';
      border-radius: 64px;
    }

  ${({ isHovered }) =>
    isHovered &&
    css`
      border-color: ${Styles.colors.blue500};
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${Styles.colors.blue500};

      &:after {
        background-color: ${Styles.colors.blue500};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border-color: ${Styles.colors.neutral300};
    `}
`;

const HiddenInput = Styled.input`
  opacity: 0;
  position: absolute;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:focus {
        + span {
          outline: 1px solid ${Styles.colors.blue500};
        }
      }
    `}
`;

const CircleWrapper = Styled.div`
  display: grid;
`;

const IconWrapper = Styled.div`
  width: 12px;
  margin-left: 8px;
  align-self: center;
  justify-self: left;
`;

export const RadioButton: FunctionComponent<IRadioButtonProps> = ({
  onChange,
  isSelected,
  id,
  dataTestId = `radio-button`,
  disabled = false,
  label,
  tooltip,
}) => {
  const { isHovered, handleHover } = useHover();
  const [iconHovered, setIconHover] = useState(false);

  const handleIconHover = (hoverState: boolean) => () => {
    setIconHover(hoverState);
  };

  const handleClick = () => {
    if (!disabled && !isSelected) {
      onChange(id);
    }
  };

  const renderTooltipIcon = () => {
    if (tooltip)
      return (
        <IconWrapper
          onMouseEnter={handleIconHover(true)}
          onMouseLeave={handleIconHover(false)}
        >
          <Icon variant="pro" name="faInfoCircle" opacity={0.6}>
            <Tooltip
              visible={iconHovered}
              arrowDirection="top"
              arrowPlacement={1}
              text={tooltip}
            />
          </Icon>
        </IconWrapper>
      );
  };

  return (
    <Wrapper
      onMouseEnter={handleHover(true, disabled)}
      onMouseLeave={handleHover(false, disabled)}
      data-testid={dataTestId}
      onClick={handleClick}
    >
      <CircleWrapper>
        <HiddenInput type="radio" id={id} value={id} disabled={disabled} />
        <AnimatedCircle
          htmlFor={id}
          name={label}
          isHovered={isHovered}
          isSelected={isSelected}
          disabled={disabled}
        />
      </CircleWrapper>
      <Label htmlFor={id} disabled={disabled}>
        {label}
      </Label>
      {renderTooltipIcon()}
    </Wrapper>
  );
};

export const Colors = {
  text: {
    lightPrimary: 'rgba(255, 255, 255, 0.96)',
    lightSecondary: 'rgba(255, 255, 255, 0.87)',
    lightPlaceholder: 'rgba(255, 255, 255, 0.54)',
    lightDisabled: 'rgba(255, 255, 255, 0.38)',
    darkPrimary: 'rgba(0, 0, 0, 0.87)',
    darkSecondary: 'rgba(0, 0, 0, 0.54)',
    darkPlaceholder: 'rgba(0, 0, 0, 0.38)',
    darkDisabled: 'rgba(0, 0, 0, 0.26)',
  },
  white: '#FFFFFF',
  black: '#000000',
  integrateBlue: '#4b8fc9',
  integrateGreen: '#a2cd4e',
  integrateOrange: '#f69d38',
  neutral50: '#FAFAFA',
  neutral100: '#EFEFEF',
  neutral300: '#CFCFCF',
  neutral500: '#A3A3A3',
  neutral700: '#737373',
  neutral900: '#424242',
  coolNeutral50: '#F3F4F5',
  coolNeutral100: '#E8EAED',
  coolNeutral300: '#C8CCD1',
  coolNeutral500: '#A1A7B1',
  coolNeutral700: '#76808B',
  coolNeutral900: '#00173A',
  indigo100: '#e5e9ff',
  indigo500: '#4d64ff',
  indigo700: '#3646b3',
  blue100: '#daeeff',
  blue500: '#2192f0',
  blue700: '#055cad',
  green100: '#e2f5d8',
  green500: '#84cc60',
  green700: '#225c16',
  yellow100: '#fbf3d7',
  yellow500: '#edc956',
  yellow700: '#ba9634',
  red100: '#ffe2df',
  red500: '#f76b5e',
  red700: '#bf3b3b',
  orange100: '#fce7d6',
  orange500: '#eba14d',
  orange700: '#b5672d',
  byzantium100: '#fce3fd',
  byzantium500: '#d782d9',
  byzantium700: '#a65a9e',
  purple100: '#ece5ff',
  purple500: '#a48eed',
  purple700: '#7765ba',
  opacity10: '1A',
  opacity20: '33',
  opacity30: '4D',
  opacity40: '66',
  opacity50: '80',
  opacity60: '99',
  opacity70: 'B3',
  opacity80: 'CC',
  opacity90: 'E6',
};

import React, {
  FunctionComponent,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import styled, { css } from 'styled-components';
import { Styles } from '../util/Styles';
import { Icon } from './Icon';
import { Tooltip } from './Tooltip';
import { useHover } from '../util/Hooks';

interface IWrapperProps {
  width?: string;
  active: boolean;
  hasError: boolean;
  hasWarning: boolean;
}

const Wrapper = styled.label<IWrapperProps>`
  box-sizing: border-box;
  display: grid;
  font-family: ${Styles.fonts.primary};
  font-size: ${Styles.fontSizes.s3};
  color: ${Styles.colors.text.darkSecondary};
  word-break: break-all;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ active }) =>
    active &&
    css`
      color: ${Styles.colors.blue500};
    `}
    
    ${({ hasWarning }) =>
    hasWarning &&
    css`
      color: ${Styles.colors.yellow500};
    `}
    
    ${({ hasError }) =>
    hasError &&
    css`
      color: ${Styles.colors.red500};
    `}
`;

interface IInputProps {
  hasError: boolean;
  hasWarning: boolean;
  maxWidth?: string;
  maxHeight?: string;
  hasIcon: boolean;
  hasClear?: boolean;
  iconPosition?: string;
  prefixText?: string;
  suffixText?: string;
  isFocused: boolean;
}

const baseInput = `
    margin-top: 8px;
    padding: 12px;
    box-sizing: border-box;
    border-radius: ${Styles.borderRadius.s};
    border: 2px solid ${Styles.colors.neutral300};
    width: 100%;
    cursor: text;
    transition: ${Styles.transitions.textfield};
    &::placeholder {
        color: ${Styles.colors.text.darkPlaceholder};
    }
    &:focus {
        outline: 0;
    }
    &:disabled {
        background: ${Styles.colors.neutral100};
        cursor: not-allowed;
    }
`;

const baseText = `
    font-size: ${Styles.fontSizes.m1};
    font-family: ${Styles.fonts.primary};
    color: ${Styles.colors.text.darkPrimary};
    font-weight: normal;
`;

const Input = styled.input<IInputProps>`
  ${baseInput};
  ${baseText};
  height: 45px;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        border-color: ${Styles.colors.blue500};
      }
    `}
  ${({ hasIcon, iconPosition }) =>
    hasIcon &&
    iconPosition &&
    iconPosition === 'left' &&
    css`
      padding-left: 26px;
    `}
    ${({ hasIcon, iconPosition }) =>
    hasIcon &&
    iconPosition &&
    iconPosition === 'right' &&
    css`
      padding-right: 26px;
    `}
    
    ${({ hasWarning }) =>
    hasWarning &&
    css`
      border-color: ${Styles.colors.yellow500};
      &:hover,
      &:focus {
        border-color: ${Styles.colors.yellow500};
      }
    `}

    ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${Styles.colors.red500};
      &:hover,
      &:focus {
        border-color: ${Styles.colors.red500};
      }
    `}
`;

const TextArea = styled.textarea<IInputProps>`
  ${baseInput}
  ${baseText};
  min-width: 100px;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        border-color: ${Styles.colors.blue500};
      }
    `}
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}
    ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

    ${({ hasWarning }) =>
    hasWarning &&
    css`
      border-color: ${Styles.colors.yellow500};
      &:hover,
      &:focus {
        border-color: ${Styles.colors.yellow500};
      }
    `}

    ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${Styles.colors.red500};
      &:hover,
      &:focus {
        border-color: ${Styles.colors.red500};
      }
    `}
`;

const InputContainer = styled.div<IInputProps>`
  ${baseInput};
  ${baseText};
  position: relative;
  display: flex;
  align-items: center;
  height: 45px;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    border-color: ${Styles.colors.blue500};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${Styles.colors.neutral100};
      cursor: not-allowed;
      &:hover {
        border-color: ${Styles.colors.neutral300};
      }
    `}

  ${({ hasIcon, iconPosition }) =>
    hasIcon &&
    iconPosition &&
    iconPosition === 'left' &&
    css`
      padding-left: 32px;
    `}

    ${({ hasIcon, iconPosition, hasClear }) =>
    ((hasIcon && iconPosition && iconPosition === 'right') || hasClear) &&
    css`
      padding-right: 32px;
    `}

    ${({ prefixText }) =>
    prefixText &&
    css`
      &:before {
        content: '${prefixText}';
        padding-right: 5px;
        color: ${Styles.colors.text.darkPlaceholder};
      }
    `}

    ${({ suffixText }) =>
    suffixText &&
    css`
      &:after {
        content: '${suffixText}';
        padding-left: 3px;
        padding-right: 3px;
        color: ${Styles.colors.text.darkPlaceholder};
      }
    `}

    ${({ isFocused }) => css`
    border-color: ${isFocused
      ? Styles.colors.blue500
      : Styles.colors.neutral300};
  `}

    ${({ hasWarning }) =>
    hasWarning &&
    css`
      border-color: ${Styles.colors.yellow500};
      &:hover {
        border-color: ${Styles.colors.yellow500};
      }
    `}

    ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${Styles.colors.red500};
      &:hover {
        border-color: ${Styles.colors.red500};
      }
    `}
`;

const InputElement = styled.input`
  ${baseText};
  width: 100%;
  border: 0;
  &:focus {
    border: 0px;
    outline: 0;
  }
  &:disabled {
    background: ${Styles.colors.neutral100};
    cursor: not-allowed;
  }
  &::placeholder {
    color: ${Styles.colors.text.darkPlaceholder};
  }
`;

interface IHelperWrapperProps {
  color: string;
}

const HelperWrapper = styled.p<IHelperWrapperProps>`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  margin-top: 4px;
  font-size: ${Styles.fontSizes.s2};
  word-break: break-all;
`;

const IconWrapper = styled.span`
  margin-right: 4px;
`;

const TooltipContainer = styled.div`
  z-index: 1;
  justify-self: start;
  align-self: center;
  color: ${Styles.colors.text.darkSecondary};
  position: relative;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 4px);
  & > div {
    transform: translateY(-50%);
  }
`;

interface ILabelWrapperProps {
  hideLabel: boolean;
}

const LabelWrapper = styled.div<ILabelWrapperProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  ${({ hideLabel }) =>
    hideLabel &&
    css`
      height: 0;
      opacity: 0;
    `}
`;

interface IIconFieldWrapperProps {
  iconPosition?: string;
}

const IconFieldWrapper = styled.div<IIconFieldWrapperProps>`
  position: absolute;
  color: ${Styles.colors.text.darkPlaceholder};
  ${({ iconPosition }) =>
    iconPosition &&
    iconPosition === 'left' &&
    css`
      left: 10px;
    `}
  ${({ iconPosition }) =>
    iconPosition &&
    iconPosition === 'right' &&
    css`
      right: 10px;
    `}
`;

interface IProps {
  label: string;
  width?: string;
  placeholder: string;
  disabled?: boolean;
  errorText?: string;
  warningText?: string;
  required?: boolean;
  helperText?: string;
  prefixText?: string;
  suffixText?: string;
  tooltip?: string;
  dataTestId?: string;
  onChange?: (val: string) => void;
  onBlur?: (val: string) => void;
  defaultValue?: string;
  multiLine?: boolean;
  maxHeight?: string;
  maxWidth?: string;
  icon?: string;
  iconPosition?: string;
  type?: string;
  maxLength?: number;
  onFocus?: () => void;
  hasClear?: boolean;
  hideLabel?: boolean;
}

export const TextField: FunctionComponent<IProps> = ({
  label,
  width,
  placeholder,
  disabled,
  errorText,
  warningText,
  required,
  helperText,
  prefixText,
  suffixText,
  tooltip,
  dataTestId = 'textField',
  onChange,
  onBlur,
  onFocus,
  defaultValue = '',
  multiLine = false,
  maxHeight,
  maxWidth,
  icon,
  iconPosition = 'right',
  type = 'text',
  maxLength,
  hasClear = false,
  hideLabel = false,
}) => {
  const [active, setActive] = useState(false);
  const { isHovered, handleHover } = useHover();
  const [val, setVal] = useState(defaultValue);

  const hasError = !disabled && errorText && !!errorText.trim().length;
  const hasWarning = !disabled && warningText && !!warningText.trim().length;
  const hasIcon = icon && !!icon.trim().length && !multiLine;
  const hasExtraElements = hasIcon || hasClear || prefixText || suffixText;

  useEffect(() => {
    setVal(defaultValue);
  }, [defaultValue]);

  const handleFocus = (toggle: boolean) => () => {
    setActive(toggle);
    onFocus && onFocus();
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value);
    setVal(e.target.value);
  };

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    handleFocus(false);
    setActive(false);
    onBlur && onBlur(e.target.value);
  };

  const renderTooltip = () => {
    return (
      <TooltipContainer
        onMouseEnter={handleHover(true)}
        onMouseLeave={handleHover(false)}
      >
        <TooltipWrapper>
          <Tooltip
            text={tooltip}
            arrowDirection="left"
            arrowPlacement={10}
            width="200px"
            visible={isHovered}
          />
        </TooltipWrapper>
        <Icon name="faInfoCircle" />
      </TooltipContainer>
    );
  };

  const renderError = () => {
    if (hasError) {
      return (
        <HelperWrapper color={Styles.colors.red500} data-testid="errorMessage">
          <IconWrapper>
            <Icon name="faExclamationCircle" />
          </IconWrapper>
          {errorText}
        </HelperWrapper>
      );
    }

    if (hasWarning) {
      return (
        <HelperWrapper
          color={Styles.colors.yellow500}
          data-testid="warningMessage"
        >
          <IconWrapper>
            <Icon name="faExclamationTriangle" />
          </IconWrapper>
          {warningText}
        </HelperWrapper>
      );
    }

    return (
      helperText && (
        <HelperWrapper
          color={Styles.colors.text.darkSecondary}
          data-testid="helperText"
        >
          {helperText}
        </HelperWrapper>
      )
    );
  };

  const renderIcon = () => {
    const shouldRender = iconPosition === 'left' || !hasClear;
    if (shouldRender) {
      return (
        <IconFieldWrapper iconPosition={iconPosition} data-testid="fieldIcon">
          <Icon name={icon} />
        </IconFieldWrapper>
      );
    }
  };

  const renderClear = () => {
    const hasInputToClear = !!val;
    if (hasInputToClear && !disabled) {
      return (
        <IconFieldWrapper iconPosition="right" data-testid="clearInputIcon">
          <Icon
            name="faTimes"
            onClick={handleClearIconClick}
            ariaLabel="clear input"
          />
        </IconFieldWrapper>
      );
    }
  };

  const handleClearIconClick = () => {
    onChange && onChange('');
    setVal('');
  };

  const renderLabel = () => {
    return (
      <LabelWrapper hideLabel={hideLabel}>
        {`${label} ${required ? '*' : ''}`}
        {tooltip && tooltip.length ? renderTooltip() : ''}
      </LabelWrapper>
    );
  };

  const renderField = () => {
    if (multiLine) {
      return (
        <TextArea
          hasError={hasError}
          hasWarning={hasWarning}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={handleFocus(true)}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          value={val}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          data-testid="textArea"
          maxLength={maxLength}
          isFocused={active}
        />
      );
    }

    if (hasExtraElements) {
      return (
        <InputContainer
          hasError={hasError}
          hasWarning={hasWarning}
          disabled={disabled}
          hasIcon={hasIcon}
          isFocused={active}
          iconPosition={iconPosition}
          prefixText={prefixText}
          suffixText={suffixText}
          data-testid="inputContainer"
          hasClear={hasClear}
        >
          {hasIcon && renderIcon()}
          {hasClear && renderClear()}
          <InputElement
            disabled={disabled}
            placeholder={placeholder}
            onFocus={handleFocus(true)}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            value={val}
            type={type}
            maxLength={maxLength}
          />
        </InputContainer>
      );
    }

    return (
      <Input
        hasError={hasError}
        hasWarning={hasWarning}
        disabled={disabled}
        placeholder={placeholder}
        onFocus={handleFocus(true)}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        value={val}
        type={type}
        maxLength={maxLength}
        isFocused={active}
      />
    );
  };

  return (
    <Wrapper
      width={width}
      active={active}
      hasError={hasError}
      hasWarning={hasWarning}
      data-testid={dataTestId}
    >
      {renderLabel()}
      {renderField()}
      {renderError()}
    </Wrapper>
  );
};

import React, {
  useState,
  useRef,
  Fragment,
  useLayoutEffect,
  useEffect,
  FC,
} from 'react';
import styled, { css } from 'styled-components';
import { Styles } from '../util/Styles';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { Chip } from './Chip';
import { KeywordSearch } from './KeywordSearch';
import { Icon } from './Icon';

const FilterContainer = styled.div`
  position: relative;
  display: flex;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const FilterOptionsContainer = styled.div`
  z-index: 2;
  position: absolute;
  width: 264px;
  top: 45px;
  padding-top: 16px;
  background-color: ${Styles.colors.white};
  border-radius: 4px;
  box-shadow: 0px 4px 20px #00000033;
`;

const ChipContainer = styled.div`
  margin: 0 16px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 6px;
  align-items: center;
`;

const SearchContainer = styled.div`
  margin: 0 16px 16px 16px;
`;

const FieldsetWrapper = styled.fieldset`
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
`;

const OptionsContainer = styled.div`
  max-height: 264px;
  overflow: auto;
  margin: 0 16px;

  & > fieldset:not(:first-child) {
    margin-top: 16px;
  }

  &::-webkit-scrollbar {
    top: 5px;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    height: 184px;
    border-radius: 4px;
    background-color: ${Styles.colors.neutral300};
  }

  scrollbar-color: ${Styles.colors.neutral300} transparent;
  scrollbar-width: thin;
  scrollbar-face-color: ${Styles.colors.neutral300};
  scrollbar-track-color: transparent;
`;

const OptionGroupTitle = styled.legend`
  margin-bottom: 16px;
  font-size: ${Styles.fontSizes.s3};
  font-family: ${Styles.fonts.primary};
  font-weight: ${Styles.fontWeights.bold};
  color: ${Styles.colors.neutral900};
`;

const CheckboxContainer = styled.div`
  margin-bottom: 12px;
`;

const FilterOptionsFooter = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
`;

interface IFooterGradientProps {
  height: number;
}

const FilterOptionsFooterGradient = styled.div<IFooterGradientProps>`
  position: sticky;
  z-index: 1;
  bottom: 0;
  width: 100%;
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
  background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
`;

const FooterButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  padding: 16px;
  column-gap: 8px;
  justify-content: flex-end;
`;

const LinkButton = styled.button`
  text-align: left;
  text-decoration: underline;
  letter-spacing: 0px;
  color: ${Styles.colors.neutral700};
  font-size: ${Styles.fontSizes.s3};
  font-family: ${Styles.fonts.primary};
  opacity: 1;
  align-self: center;
  cursor: pointer;
  border: 0;
  background: transparent;
  &:hover {
    color: ${Styles.colors.neutral500};
  }
`;

const MessageTemplate = styled.div`
  display: grid;
  justify-items: center;
  font-family: ${Styles.fonts.primary};
  text-align: center;
  font-size: ${Styles.fontSizes.s3};
  color: ${Styles.colors.neutral700};

  div {
    max-width: 180px;
    margin-top: 16px;
  }

  button {
    margin-top: 16px;
  }
`;

interface IFilterGroup {
  [optionValue: string]: string;
}

interface IFilterGroupLoading {
  isLoading: boolean;
}

interface IFilterGroupError {
  onRetryClick: () => void;
}

type FilterGroup = IFilterGroup | IFilterGroupLoading | IFilterGroupError;

export type FilterOptions = {
  [groupName: string]: FilterGroup;
};

export interface IFilterProps {
  filterOptions: FilterOptions;
  applyFilters?: (selectedOptions: string[]) => void;
  defaultOptions?: string[];
  dataTestId?: string;
}

export const Filter: FC<IFilterProps> = ({
  filterOptions,
  applyFilters,
  defaultOptions = [],
  dataTestId = 'filter',
}) => {
  const optionsContainerRef = useRef(null);
  const [currentFilterOptions, setCurrentFilterOptions] = useState([]);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [optionsGradientHeight, setOptionsGradientHeight] = useState(40);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState({});

  const initialRender = useRef(true);

  const isSearchActive = searchTerm !== '';

  useLayoutEffect(() => {
    const hasNoScrollBar =
      optionsContainerRef &&
      optionsContainerRef.current &&
      optionsContainerRef.current.scrollHeight <=
        optionsContainerRef.current.clientHeight;

    hasNoScrollBar && setOptionsGradientHeight(0);
  }, [showFilterOptions]);

  useEffect(() => {
    defaultOptions.length && setCurrentFilterOptions(defaultOptions);
    initialRender.current = true;
  }, [defaultOptions]);

  useEffect(() => {
    if (initialRender.current) {
      if (defaultOptions === currentFilterOptions) {
        initialRender.current = false;
      }
      return;
    }

    if (!showFilterOptions) {
      applyFilters && applyFilters(currentFilterOptions);
    }
  }, [currentFilterOptions]);

  useEffect(() => {
    if (!isSearchActive) {
      setSearchResults({});
      return;
    }

    const results = {};
    Object.entries(filterOptions).forEach(([heading, items]) => {
      const hasError = (items as IFilterGroupError).onRetryClick !== undefined;
      const isLoading = (items as IFilterGroupLoading).isLoading !== undefined;
      const hasGroupOptions = !hasError && !isLoading;

      const filteredItems = Object.keys(items).reduce(
        (accumulator, currentValue) => {
          if (
            hasGroupOptions &&
            items[currentValue]
              .toLowerCase()
              .includes(searchTerm.toLowerCase().trim())
          ) {
            accumulator[currentValue] = items[currentValue];
          }

          return accumulator;
        },
        {}
      );

      if (Object.keys(filteredItems).length) {
        results[heading] = filteredItems;
      }
    });

    setSearchResults(results);
  }, [searchTerm]);

  const handleOptionContainerScroll = (e: Event) => {
    const { scrollHeight, clientHeight, scrollTop } = e.target as Element;
    setOptionsGradientHeight(
      (1 / (scrollHeight - clientHeight)) *
        (scrollHeight - clientHeight - scrollTop) *
        40
    );
  };

  const handleOptionToggle = (option: string) => (isChecked: boolean) => {
    const isOptionInCurrentIndex = currentFilterOptions.indexOf(option) !== -1;
    if (isChecked && !isOptionInCurrentIndex) {
      setCurrentFilterOptions([...currentFilterOptions, option]);
    } else if (!isChecked && isOptionInCurrentIndex) {
      setCurrentFilterOptions(
        currentFilterOptions.filter((value) => option !== value)
      );
    }
  };

  const handleFilterChipClose = (option: string) => () => {
    setCurrentFilterOptions(
      currentFilterOptions.filter((value) => option !== value)
    );
  };

  const resetFilters = () => setCurrentFilterOptions([]);

  const handleApplyClick = () => {
    applyFilters && applyFilters(currentFilterOptions);
    setShowFilterOptions(false);
    setSearchTerm('');
  };

  const isCheckboxChecked = (optionKey) =>
    currentFilterOptions.includes(optionKey);

  const handleOnFilterButtonClick = () =>
    setShowFilterOptions(!showFilterOptions);

  const getChipLabelFromKey = (key: number) => {
    let chipValue = '';
    const chipGroupName = Object.keys(filterOptions).find((groupName) => {
      if (key in filterOptions[groupName]) {
        chipValue = filterOptions[groupName][key];
        return true;
      } else {
        return false;
      }
    });

    return chipGroupName ? `${chipGroupName}: ${chipValue}` : `${chipValue}`;
  };

  const renderFilterOverview = () =>
    currentFilterOptions.length !== 0 && (
      <Fragment>
        <ChipContainer id="filterChipContainer">
          {currentFilterOptions.map((option, index) => (
            <Chip
              label={getChipLabelFromKey(option)}
              key={index}
              aria-labelledby="filterOptions"
              onClose={handleFilterChipClose(option)}
              dataTestId={`${dataTestId}-${option}-filterChip`}
              maxWidth="200px"
            />
          ))}
        </ChipContainer>
        <LinkButton
          aria-labelledby="filterOptions"
          onClick={resetFilters}
          data-testid={`${dataTestId}-clearButton`}
        >
          Clear filters
        </LinkButton>
      </Fragment>
    );

  const handleOverlayClick = () => {
    setShowFilterOptions(false);
    setSearchTerm('');
  };

  const renderGroupError = (groupName: string, groupOptions: FilterGroup) => {
    const hasError =
      (groupOptions as IFilterGroupError).onRetryClick !== undefined;

    if (hasError) {
      const { onRetryClick } = groupOptions as IFilterGroupError;

      const handleRetry = () => onRetryClick();

      return (
        <MessageTemplate>
          <Icon
            name="faExclamationTriangle"
            variant="light"
            color={Styles.colors.red500}
            fontSize={Styles.fontSizes.l4}
            ariaLabel="error"
          />
          <div>There was a problem loading these filters</div>
          <LinkButton
            onClick={handleRetry}
            data-testid={`${dataTestId}-${groupName}-retry-button`}
          >
            Retry
          </LinkButton>
        </MessageTemplate>
      );
    }
  };

  const renderGroupLoading = (groupOptions: FilterGroup) => {
    const isLoading = (groupOptions as IFilterGroupLoading).isLoading === true;

    if (isLoading) {
      return (
        <MessageTemplate>
          <Icon
            name="faSpinner"
            color={Styles.colors.neutral300}
            fontSize={Styles.fontSizes.l4}
            ariaLabel="loading"
            spin
          />
          <div>These filters are still loading</div>
        </MessageTemplate>
      );
    }
  };

  const renderGroupCheckboxes = (groupOptions: FilterGroup) => {
    const hasError =
      (groupOptions as IFilterGroupError).onRetryClick !== undefined;
    const isLoading =
      (groupOptions as IFilterGroupLoading).isLoading !== undefined;
    const hasGroupOptions = !hasError && !isLoading;

    if (hasGroupOptions) {
      return Object.keys(groupOptions).map((optionKey, index) => (
        <CheckboxContainer key={optionKey}>
          <Checkbox
            aria-labeledby="filterOptions"
            key={index}
            dataTestId={`${dataTestId}-${optionKey}-checkbox`}
            onChange={handleOptionToggle(optionKey)}
            isDefaultChecked={isCheckboxChecked(optionKey)}
            label={groupOptions[optionKey]}
            wrapLabel
          />
        </CheckboxContainer>
      ));
    }
  };

  const renderGroupOptions = (filters: FilterOptions) => {
    return Object.keys(filters).map((key) => (
      <Fragment key={key}>
        <FieldsetWrapper name={key}>
          {key && <OptionGroupTitle>{key}</OptionGroupTitle>}
          {renderGroupCheckboxes(filters[key])}
          {renderGroupLoading(filters[key])}
          {renderGroupError(key, filters[key])}
        </FieldsetWrapper>
      </Fragment>
    ));
  };

  const renderEmptyResult = () => {
    const noResults = Object.keys(searchResults).length === 0;
    if (isSearchActive && noResults) {
      return (
        <MessageTemplate>
          <Icon
            name="faFolderOpen"
            color={Styles.colors.neutral300}
            fontSize={Styles.fontSizes.l4}
            ariaLabel="no results"
          />
          <div>We couldn’t find any filters matching your query</div>
        </MessageTemplate>
      );
    }
  };

  const renderOptions = () => {
    const filters = isSearchActive ? searchResults : filterOptions;

    return (
      showFilterOptions && (
        <FilterOptionsContainer
          id="filterOptions"
          data-testid={`${dataTestId}-options`}
        >
          <SearchContainer>
            <KeywordSearch
              data-testid={`${dataTestId}-search-container`}
              onChange={setSearchTerm}
            />
          </SearchContainer>
          <OptionsContainer
            ref={optionsContainerRef}
            onScroll={handleOptionContainerScroll}
          >
            {renderGroupOptions(filters)}
            {renderEmptyResult()}
            <FilterOptionsFooterGradient height={optionsGradientHeight} />
          </OptionsContainer>
          <FilterOptionsFooter>
            <FooterButtonContainer>
              <Button
                aria-labelledby="filterOptions"
                dataTestId={`${dataTestId}-resetFooterButton`}
                onClick={resetFilters}
                label="Reset"
                type="secondary"
              />
              <Button
                aria-labelledby="filterOptions"
                dataTestId={`${dataTestId}-applyFooterButton`}
                onClick={handleApplyClick}
                label="Apply"
              />
            </FooterButtonContainer>
          </FilterOptionsFooter>
        </FilterOptionsContainer>
      )
    );
  };

  const renderOverlay = () =>
    showFilterOptions && (
      <Overlay
        data-testid={`${dataTestId}-overlay`}
        onClick={handleOverlayClick}
      />
    );

  return (
    <>
      <FilterContainer data-testid={dataTestId}>
        <Button
          dataTestId={`${dataTestId}-filterButton`}
          aria-controls="filterOptions"
          aria-describedby="filterChipContainer"
          aria-haspopup="true"
          aria-expanded={showFilterOptions}
          onClick={handleOnFilterButtonClick}
          label="Filter"
          type="secondary"
          icon="faFilter"
        />
        {renderFilterOverview()}
        {renderOptions()}
      </FilterContainer>
      {renderOverlay()}
    </>
  );
};

import React, { FunctionComponent, useState, Fragment } from 'react';
import Styled, { css } from 'styled-components';
import { DropdownOptions, DropdownItem } from './dropdown/DropdownOptions';
import { TextField } from './TextField';

interface IKeywordSearchProps {
  label?: string;
  placeholder?: string;
  width?: string;
  disabled?: boolean;
  errorText?: string;
  warningText?: string;
  items?: DropdownItem[];
  onChange: (val: string) => void;
  onSelect?: (val: any) => void;
  dataTestId?: string;
}

interface IOverlayProps {
  isClosed: boolean;
}

const Overlay = Styled.div<IOverlayProps>`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  ${({ isClosed }) =>
    isClosed &&
    css`
      display: none;
    `}
`;

interface IWrapperProps {
  width?: string;
}

const Wrapper = Styled.div<IWrapperProps>`
  position: relative;
  z-index: 1;
  ${({ width }) => css`
    width: ${width};
  `}
`;

const OptionsWrapper = Styled.div`
  position: absolute;
  top: 53px;
  width: 100%;
`;

export const KeywordSearch: FunctionComponent<IKeywordSearchProps> = ({
  label = 'Search',
  placeholder = 'Search',
  width = '100%',
  disabled = false,
  errorText,
  warningText,
  items = [],
  onChange,
  onSelect,
  dataTestId = 'keyword-search',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const handleChange = (value: string) => {
    const hasValue = value && value.trim().length;
    if (hasValue) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      onSelect && onSelect(null);
    }

    onChange(value);
    setSearchInput(value);
  };

  const handleValueChange = (item: DropdownItem) => {
    setIsOpen(false);
    setSearchInput(item.value);
    onSelect && onSelect(item);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const renderOptions = () => {
    const shouldRender = isOpen && items.length;
    if (shouldRender) {
      return (
        <OptionsWrapper>
          <DropdownOptions
            items={items}
            onValueChange={handleValueChange}
            width={width}
          />
        </OptionsWrapper>
      );
    }
  };

  return (
    <Fragment>
      <Overlay
        data-testid={`${dataTestId}-overlay`}
        isClosed={!isOpen}
        onClick={closeDropdown}
      />
      <Wrapper width={width}>
        <TextField
          label={label}
          placeholder={placeholder}
          width={width}
          disabled={disabled}
          errorText={errorText}
          warningText={warningText}
          onChange={handleChange}
          dataTestId={dataTestId}
          hasClear
          hideLabel
          icon="faSearch"
          iconPosition="left"
          defaultValue={searchInput}
        />
        {renderOptions()}
      </Wrapper>
    </Fragment>
  );
};

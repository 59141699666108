import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHover } from '../util/Hooks';
import { Styles } from '../util/Styles';
import Styled, { css } from 'styled-components';

export interface ICheckboxProps {
  isDefaultChecked?: boolean;
  disabled?: boolean;
  onChange: Function;
  dataTestId?: string;
  label: string;
  hideLabel?: boolean;
  maxWidth?: string;
  wrapLabel?: boolean;
}

interface ICheckboxAndLabelWrapperProps {
  maxWidth?: string;
}

const CheckboxAndLabelWrapper = Styled.label<ICheckboxAndLabelWrapperProps>`
font-family: ${Styles.fonts.primary};
color: ${Styles.colors.text.darkPrimary};
font-weight: ${Styles.fontWeights.regular};
font-size: ${Styles.fontSizes.s3};
display: grid;
grid-template-columns: 18px 1fr;
align-self: center;
align-items: center;
line-height: 20px;
margin: 0;
${({ maxWidth }) =>
  maxWidth &&
  css`
    max-width: ${maxWidth};
  `}
`;

interface ICheckboxWrapperProps {
  dataTestId: string;
  disabled: boolean;
}

const CheckboxWrapper = Styled.span<ICheckboxWrapperProps>`
position: relative;
white-space: nowrap;
vertical-align: middle;
border-radius: ${Styles.borderRadius.xs};
`;

const CheckboxInputArea = Styled.input`
position: absolute;
z-index: 1;
width: 100%;
height: 100%;
opacity: 0;
cursor: pointer;
border: none;
padding: 0;
left: 0;
top: 0;

${({ disabled }) =>
  disabled &&
  css`
    cursor: not-allowed;
  `}
`;

interface ICheckboxFillProps {
  isChecked: boolean;
  isHovered: boolean;
  disabled: boolean;
}

const CheckboxFill = Styled.span<ICheckboxFillProps>`
position: relative;
display: block;
width: 20px;
height: 20px;
background-color: ${Styles.colors.white};
border: 2px solid ${Styles.colors.neutral500};
border-radius: ${Styles.borderRadius.xs};
border-collapse: separate;
transition: all 0.1s;
box-sizing: border-box;

&:after {
  position: absolute;
  top: 44%;
  left: 15%;
  display: table;
  width: 6px;
  height: 13px;
  border: 2px solid ${Styles.colors.white};
  border-top: 0;
  border-left: 0;
  -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
  box-sizing: border-box;
}

${({ isHovered }) =>
  isHovered &&
  css`
    border-color: ${Styles.colors.blue500};
  `}

${({ isChecked }) =>
  isChecked &&
  css`
    background-color: ${Styles.colors.blue500};
    border-color: ${Styles.colors.blue500};

    &:after {
      position: absolute;
      border: 2px solid ${Styles.colors.white};
      border-top: 0;
      border-left: 0;
      -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.13s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: ' ';
    }
  `}

${({ disabled }) =>
  disabled &&
  css`
    border-color: ${Styles.colors.neutral300};

    ${({ isChecked }) =>
      isChecked &&
      css`
        background-color: ${Styles.colors.neutral300};

        &:after {
          border-color: ${Styles.colors.white};
        }
      `}
  `}
`;

interface ILabelSpanProps {
  disabled: boolean;
  hideLabel: boolean;
  wrapLabel: boolean;
}

const LabelSpan = Styled.span<ILabelSpanProps>`
user-select: none;
cursor: pointer;
text-align: left;
font-weight: 500;
${({ wrapLabel }) =>
  wrapLabel &&
  css`
    padding: 0 0 0 8px;
    word-wrap: break-word;
  `}
${({ wrapLabel }) =>
  !wrapLabel &&
  css`
    padding: 1px 0 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
${({ disabled }) =>
  disabled &&
  css`
    color: ${Styles.colors.neutral500};
    cursor: not-allowed;
  `}
${({ hideLabel }) =>
  hideLabel &&
  css`
    padding: 0;
    width: 0;
  `}
`;

export const Checkbox: FunctionComponent<ICheckboxProps> = ({
  isDefaultChecked = false,
  onChange,
  dataTestId = 'checkbox',
  disabled = false,
  label,
  hideLabel = false,
  maxWidth,
  wrapLabel = false,
}) => {
  const [isChecked, setIsChecked] = useState(isDefaultChecked);
  const { isHovered, handleHover } = useHover();

  useEffect(() => {
    setIsChecked(isDefaultChecked);
  }, [isDefaultChecked]);

  const handleClick = () => {
    if (!disabled) {
      onChange(!isChecked);
      setIsChecked(!isChecked);
    }
  };

  return (
    <CheckboxAndLabelWrapper
      onMouseEnter={handleHover(true, disabled)}
      onMouseLeave={handleHover(false, disabled)}
      data-testid={dataTestId}
      maxWidth={maxWidth}
    >
      <CheckboxWrapper onClick={handleClick}>
        <CheckboxInputArea
          disabled={disabled}
          role="checkbox"
          checked={isChecked}
          aria-checked={isChecked}
          onChange={handleClick}
        />
        <CheckboxFill
          isHovered={isHovered}
          disabled={disabled}
          isChecked={isChecked}
        />
      </CheckboxWrapper>
      <LabelSpan
        disabled={disabled}
        hideLabel={hideLabel}
        wrapLabel={wrapLabel}
      >
        {label}
      </LabelSpan>
    </CheckboxAndLabelWrapper>
  );
};

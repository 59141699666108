import React, { FunctionComponent, useState, useEffect } from 'react';
import Styled, { css } from 'styled-components';
import { Styles } from '../util/Styles';
import { Icon } from './Icon';

const Wrapper = Styled.div`
    display: grid;
    grid-template-columns: 48px 224px 48px;
    height: auto;
    margin-top: 8px;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); // TODO:: Seperate this logic into the Styles
    color: ${Styles.colors.white};
    background-color: ${Styles.colors.white};
    transform: translateX(calc(-100% - 10px));
    box-sizing: border-box;
    transition: 0.34s ease-in-out;
    border-radius: ${Styles.borderRadius.s};

    ${({ visible }) =>
      visible &&
      css`
        transform: translateX(24px);
      `}

    ${({ hasClosed }) =>
      hasClosed &&
      css`
        display: none;
      `}
`;
interface IIconWrapperProps {
  type: 'success' | 'error' | 'info' | 'pending';
}

const IconWrapper = Styled.div<IIconWrapperProps>`
    display: grid;
    grid-column: 1;
    border-top-left-radius: ${Styles.borderRadius.s};
    border-bottom-left-radius: ${Styles.borderRadius.s};
    align-items: center;
    justify-content: center;
    background-color: #253238; //TODO::: FIND THE RIGHT COLOUR IN STYLES FOR THIS
    font-size: ${Styles.fontSizes.m4};
    ${({ type }) =>
      type === 'success' &&
      css`
        color: ${Styles.colors.green500};
      `}
    ${({ type }) =>
      type === 'error' &&
      css`
        color: #f76a5e;
      `}

    ${({ type }) =>
      type === 'info' &&
      css`
        color: ${Styles.colors.blue500};
      `}
    ${({ type }) =>
      type === 'pending' &&
      css`
        color: ${Styles.colors.yellow500};
      `}
`;

const Body = Styled.div`
    grid-column: 2;
    background-color: ${Styles.colors.white};
    padding: 16px;
    font-family: ${Styles.fonts.primary};
`;

const Title = Styled.h3`
    font-size: ${Styles.fontSizes.m1};
    font-weight: ${Styles.fontWeights.semibold};
    margin: 0;
    color: black;
`;

const Text = Styled.p`
    font-size: ${Styles.fontSizes.s2};
    margin: 8px 0 0 0;
    padding: 0;
    color: black;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: elipsis;
`;

const CloseWrapper = Styled.div`
    grid-column: 3;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: ${Styles.colors.neutral50};
    font-size: ${Styles.fontSizes.m4};
    color: ${Styles.colors.text.darkSecondary};
    cursor: pointer;
    border-top-right-radius: ${Styles.borderRadius.s};
    border-bottom-right-radius: ${Styles.borderRadius.s};
    &:hover {
        background-color: ${Styles.colors.neutral300};
        color: ${Styles.colors.text.darkPrimary};
    }
`;

interface INotificationProps {
  title: string;
  text: string;
  type: 'success' | 'error' | 'info' | 'pending';
  duration?: number;
  dataTestId?: string;
  hasClose?: boolean;
}

export const Notification: FunctionComponent<INotificationProps> = ({
  title,
  text,
  duration = 4500,
  type = 'success',
  dataTestId = 'notification',
  hasClose = true,
}) => {
  const [visible, setVisible] = useState(false);
  const [hasClosed, setHasClosed] = useState(false);

  const deleteNotification = () => {
    if (hasClose) {
      return setTimeout(() => {
        setHasClosed(true);
      }, 400);
    }

    return setTimeout(() => {
      setHasClosed(true);
    }, duration + 400);
  };

  const handleClose = () => {
    setVisible(false);
    deleteNotification();
  };

  useEffect(() => {
    let disappearTimeout, deleteTimeout;

    if (!hasClose) {
      disappearTimeout = setTimeout(() => {
        setVisible(false);
      }, duration);
      deleteTimeout = deleteNotification();
    }
    setVisible(true);

    return () => {
      disappearTimeout && clearTimeout(disappearTimeout);
      deleteTimeout && clearTimeout(deleteTimeout);
    };
  }, []);

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return (
          <Icon
            ariaLabel="Success"
            dataTestId="successIcon"
            name="faCheck"
          ></Icon>
        );
      case 'error':
        return (
          <Icon
            ariaLabel="Error"
            dataTestId="errorIcon"
            name="faExclamation"
          ></Icon>
        );
      case 'info':
        return <Icon ariaLabel="Info" dataTestId="infoIcon" name="faInfo" />;
      case 'pending':
        return (
          <Icon
            ariaLabel="Pending"
            dataTestId="pendingIcon"
            name="faStopwatch"
            variant="pro"
          />
        );
      default:
        return <Icon ariaLabel="default" name="faCheck"></Icon>;
    }
  };

  const renderCloseButton = () => {
    if (hasClose) {
      return (
        <CloseWrapper onClick={handleClose} data-testid="notification-close">
          <Icon ariaLabel="Close Button" variant="light" name="faTimes" />
        </CloseWrapper>
      );
    }
  };

  return (
    <Wrapper
      visible={visible}
      type={type}
      hasClosed={hasClosed}
      data-testid={dataTestId}
    >
      <IconWrapper type={type}>{renderIcon()}</IconWrapper>
      <Body>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Body>
      {renderCloseButton()}
    </Wrapper>
  );
};

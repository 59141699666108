import React, { FunctionComponent } from 'react';
import Styled from 'styled-components';
import { Notification } from './Notification';

const NotificationWrapper = Styled.div`
    display: grid;
    min-width: 320px;
    width: auto;
    grid-row-gap: 16px;
    position: absolute;
    bottom: 24px;
    box-sizing: border-box;
`;

export interface INotification {
  text: string;
  type: 'success' | 'error' | 'pending' | 'info';
  title: string;
  dataTestId?: string;
  hasClose?: boolean;
}

interface INotificationsProps {
  notifications: Array<INotification>;
  duration?: number;
  dataTestId?: string;
}

export const Notifications: FunctionComponent<INotificationsProps> = ({
  notifications = [],
  duration = 4500,
  dataTestId = 'notifications',
}) => {
  const renderNotifications = () => {
    if (notifications.length) {
      return notifications.map(
        ({ text, type, title, hasClose = true }, index) => {
          return (
            <Notification
              title={title}
              duration={duration}
              hasClose={hasClose}
              text={text}
              type={type}
              key={`${index} ${text}`}
            />
          );
        }
      );
    }
  };

  return (
    <NotificationWrapper data-testid={dataTestId}>
      {renderNotifications()}
    </NotificationWrapper>
  );
};
